import React from "react";

import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Button, DropdownToggle } from "reactstrap";

import logoDark from "../../assets/images/logos/logo-dark.png";
import logoLight from "../../assets/images/logos/logo-light.png";
import logoSmLight from "../../assets/images/logos/logo-sm-light.png";
import logoSm from "../../assets/images/logos/logo-sm.png";
import { selectSidebarType, APP_SET_SIDEBAR_TYPE } from "../../store/app/appSlice";
import CartDropdown from "../common/TopbarDropdown/CartDropdown";
import { MessagesDropdown } from "../common/TopbarDropdown/MessagesDropdown";
import NotificationDropdown from "../common/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../common/TopbarDropdown/ProfileMenu";

const Header = (props: any) => {
  const sideBarType = useSelector(selectSidebarType);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function tToggle() {
    /*
      set logic for changing sidebar
    */
    if (document.body.clientWidth >= 993) {
      // desktop view
      if (sideBarType === "default") {
        dispatch(APP_SET_SIDEBAR_TYPE("small"));
      } else if (sideBarType === "small" || sideBarType === "compact") {
        dispatch(APP_SET_SIDEBAR_TYPE("default"));
      }
    } else {
      // mobile view
      document.body.classList.toggle("sidebar-enable");
      dispatch(APP_SET_SIDEBAR_TYPE("default"));
    }
  }

  return (
    <>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logoSm} alt="" height="50" />
                </span>
                <span className="logo-lg">
                  <img src={logoDark} alt="" height="50" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoSmLight} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logoLight} alt="" height="50" />
                </span>
              </Link>
            </div>
            <button
              type="button"
              onClick={() => {
                tToggle();
              }}
              className="btn btn-sm px-3 font-size-16 header-item d-inline-block d-lg-none"
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>

            <div className="header-btn-group ms-2">
              <div className="ms-4">
                <Button
                  id="create-order"
                  color="success"
                  onClick={() => {
                    navigate("/orders/create");
                  }}
                >
                  Create order +
                </Button>
              </div>
              {/* <Button color="success">Create product +</Button> */}
            </div>
          </div>
          <ul className="d-flex">
            {/* <MessagesDropdown /> */}
            <CartDropdown />
            <NotificationDropdown />
            <NotificationDropdown isNews />
            <ProfileMenu />
          </ul>
        </div>
      </header>
    </>
  );
};

export default withTranslation()(Header);
