import React, { useCallback, useEffect, useMemo, useState } from "react";

import BootstrapTable, { TableChangeState } from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator";
// @ts-ignore
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Card, CardBody, Col, Row } from "reactstrap";

import { IProductBackofficeAssetItem } from "../../../common/interfaces/backoffice.interface";
import { getErrorMessage, getImageUrl } from "../../../common/utils/formatter.util";
import { CreateProductAssetModal } from "../../../components/backoffice/modals/CreateProductAssetModal";
import { Loader } from "../../../components/common/Loader";
import { useServiceContainer } from "../../../hooks/useServiceContainer";

const DEFAULT_PER_PAGE = 10;

const PAGE_SIZE_OPTIONS = [
  {
    text: "10",
    value: 10
  },
  {
    text: "20",
    value: 20
  },
  {
    text: "50",
    value: 50
  },
  {
    text: "100",
    value: 100
  }
];

export const ProductAssetsManagement = () => {
  const { id } = useParams();

  const [assets, setAssets] = useState<IProductBackofficeAssetItem[]>([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE);
  const { backofficeService } = useServiceContainer();
  const [page, setPage] = useState(1);

  const paginatedAssets = useMemo(() => {
    return assets.slice((page - 1) * perPage, page * perPage);
  }, [assets, page, perPage]);

  const toggleEntity = useCallback(
    async (assetId: number, state: boolean) => {
      if (!id) return;
      setLoader(true);
      try {
        await backofficeService.updateProductAssetById(+id, assetId, { is_deleted: state ? 0 : 1 });
        setAssets(
          assets.map((item) => {
            if (item.id === assetId) {
              item.is_deleted = state ? 0 : 1;
            }
            return item;
          })
        );
      } catch (e) {
        console.error(e);
      } finally {
        setLoader(false);
      }
    },
    [assets]
  );

  const fetch = async () => {
    try {
      if (!id) return;
      setLoader(true);
      const res = await backofficeService.getProductAssets(+id);
      setAssets(res);
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  const columns = [
    {
      dataField: "id",
      text: "Id"
    },
    {
      dataField: "title",
      text: "Title"
    },
    // {
    //   dataField: "code",
    //   text: "Code"
    // },
    {
      dataField: "weight",
      text: "Weight"
    },
    {
      dataField: "balance",
      text: "Balance"
    },
    {
      dataField: "image",
      text: "Image",
      formatter: (cellContent: string) => {
        return (
          <a href={getImageUrl(cellContent)} target="_blank" rel="noreferrer" className="text-decoration-none">
            <img src={getImageUrl(cellContent)} alt="Print file" />
          </a>
        );
      }
    },
    {
      dataField: "is_deleted",
      text: "Actions",
      formatter: (cellContent: string, row: IProductBackofficeAssetItem) => {
        return (
          <>
            <Button
              size="sm"
              color={row.is_deleted ? "success" : "danger"}
              onClick={() => {
                toggleEntity(row.id, row.is_deleted === 1).then(() => null);
              }}
            >
              {row.is_deleted ? "Activate" : "Deactivate"}
            </Button>
            <Link to={`/backoffice/products/${id}/assets/${row.id}/edit`} className="btn btn-sm ms-2 btn-primary">
              Edit
            </Link>
          </>
        );
      }
    }
  ];

  const pageOptions = {
    sizePerPage: DEFAULT_PER_PAGE,
    page,
    sizePerPageList: PAGE_SIZE_OPTIONS,
    totalSize: assets.length || 0,
    custom: true
  };

  const onTableChange = (event: string, data: TableChangeState<any>) => {
    switch (event) {
      case "pagination":
        setPage(data.page);
        setPerPage(data.sizePerPage);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    fetch().then(() => null);
  }, []);

  return (
    <div className="data-table">
      <h3>
        <Link to={`/backoffice/products/${id}/edit`} className="btn btn-primary btn-sm me-3 mb-1">
          <i className="bx bx-arrow-back" /> Back
        </Link>
        Product #{id} assets management
      </h3>
      <Col md={12}>
        <Card>
          <CardBody>
            <button
              type="button"
              className="btn btn-success waves-effect w-100 ms-3"
              onClick={() => {
                setShowCreateModal(true);
              }}
            >
              Create new asset
            </button>
          </CardBody>
        </Card>
      </Col>
      <Card className="border">
        {loader && <Loader />}
        <CardBody>
          <PaginationProvider pagination={paginationFactory(pageOptions)}>
            {({ paginationProps, paginationTableProps }: any) => (
              <ToolkitProvider keyField="id" columns={columns} data={paginatedAssets} search>
                {(toolkitProps: any) => (
                  <>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <BootstrapTable
                            bootstrap4
                            remote
                            onTableChange={onTableChange}
                            bordered={false}
                            striped={false}
                            filter={filterFactory()}
                            classes="table table-sm align-middle table-nowrap"
                            headerWrapperClasses="thead-light"
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                            keyField="id"
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="align-items-md-center mt-30">
                      <Col className="inner-custom-pagination d-flex">
                        <div className="d-inline">
                          <SizePerPageDropdownStandalone {...paginationProps} />
                        </div>
                        <div className="text-md-right ms-auto">
                          <PaginationListStandalone {...paginationProps} />
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        </CardBody>
      </Card>

      {id && (
        <CreateProductAssetModal
          show={showCreateModal}
          toggle={() => {
            setShowCreateModal(!showCreateModal);
            fetch().then(() => null);
          }}
          productId={+id}
        />
      )}
    </div>
  );
};
