import React from "react";

import classNames from "classnames";
import { NavItem, NavLink } from "reactstrap";

interface Props<T> {
  white?: boolean;
  activeStep: T;
  onStepChange: (step: T) => void;
  passedSteps?: T[];
  stepsData: { stepIdx: T; title: string }[];
}

export const WizardStepNavigation = <T,>({ activeStep, onStepChange, passedSteps, stepsData, white }: Props<T>) => {
  return (
    <div
      className={classNames("wizard-steps", {
        "wizard-steps--white": white
      })}
    >
      <div className="steps">
        <ul>
          {stepsData.map(({ stepIdx, title }, idx) => (
            <NavItem
              className={classNames({
                current: activeStep === stepIdx,
                disabled: !(passedSteps || []).includes(stepIdx) && activeStep !== stepIdx,
                passed: (passedSteps || []).includes(stepIdx) && activeStep !== stepIdx
              })}
              key={idx}
            >
              <NavLink
                disabled={!(passedSteps || []).includes(stepIdx)}
                className={classNames("h-100", { current: activeStep === stepIdx })}
                onClick={() => {
                  onStepChange(stepIdx);
                }}
              >
                <span className="number">
                  {(passedSteps || []).includes(stepIdx) && activeStep !== stepIdx ? (
                    <i className="bx bx-check" />
                  ) : (
                    idx + 1
                  )}
                </span>{" "}
                <span className="title">{title}</span>
              </NavLink>
            </NavItem>
          ))}
        </ul>
      </div>
    </div>
  );
};
