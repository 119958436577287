import React, { useCallback, useEffect, useMemo, useState } from "react";

import { FormProvider } from "react-hook-form";
import { toast } from "react-toastify";
import * as Yup from "yup";

import {
  AutoMockupWizardStep,
  CUSTOM_MOCKUP_WIZARD_STEPS_DATA,
  CustomMockupWizardStep
} from "../../../../common/interfaces/mockup-wizard.interface";
import { ExtendedFile } from "../../../../common/interfaces/vendor.interface";
import { getErrorMessage } from "../../../../common/utils/formatter.util";
import { useCustomForm } from "../../../../hooks/useCustomForm";
import { useServiceContainer } from "../../../../hooks/useServiceContainer";
import { useAppSelector } from "../../../../store/store";
import { Loader } from "../../../common/Loader";
import { WizardStepControls } from "../../../wizards/common/WizardStepControls";
import { WizardStepNavigation } from "../../../wizards/common/WizardStepNavigation";
import { ProductTypeSelect } from "../common/ProductTypeSelect";
import { CustomMockupSettings } from "./CustomMockupSettings";
import { MockupFileUpload } from "./MockupFileUpload";

interface Props {
  onSubmit: () => void;
}

const STEPS_MAP = {
  [CustomMockupWizardStep.PRODUCT_TYPE_SELECT]: {
    next: CustomMockupWizardStep.FILE_UPLOAD,
    previous: null
  },
  [CustomMockupWizardStep.FILE_UPLOAD]: {
    next: CustomMockupWizardStep.SETTINGS,
    previous: CustomMockupWizardStep.PRODUCT_TYPE_SELECT
  },
  [CustomMockupWizardStep.SETTINGS]: {
    next: null,
    previous: CustomMockupWizardStep.FILE_UPLOAD
  }
};

export const CustomMockupWizard: React.FC<Props> = ({ onSubmit }) => {
  const [loader, setLoader] = useState(false);
  const [step, setStep] = useState<CustomMockupWizardStep>(CustomMockupWizardStep.PRODUCT_TYPE_SELECT);
  const { mockupService } = useServiceContainer();
  const methods = useCustomForm({
    schema: Yup.object().shape({})
  });

  const stateValues = useAppSelector((state) => ({
    selectedProductType: state.productTypes.selectedProduct
  }));

  const { handleSubmit, updateSchema, watch } = methods;

  const uploadedMockupFiles = watch("uploadedFiles");

  const wizardPassedSteps = useMemo(() => {
    const steps = [];

    if (!stateValues) return [];

    if (stateValues.selectedProductType && step !== CustomMockupWizardStep.PRODUCT_TYPE_SELECT) {
      steps.push(CustomMockupWizardStep.PRODUCT_TYPE_SELECT);
    }

    if (uploadedMockupFiles?.length > 0 && step !== CustomMockupWizardStep.FILE_UPLOAD) {
      steps.push(CustomMockupWizardStep.FILE_UPLOAD);
    }

    return steps;
  }, [stateValues, uploadedMockupFiles]);

  const handleStepChange = useCallback((newStep: CustomMockupWizardStep) => {
    setStep(newStep);
  }, []);

  const onSubmitHandler = () => {
    const current = STEPS_MAP[step];
    if (!current) return;
    if (current.next) {
      setStep(current.next);
    } else {
      const values = methods.getValues();
      uploadMockups(values).then(() => null);
    }
  };

  const uploadMockups = async (values: any) => {
    setLoader(true);
    try {
      const res = await mockupService.uploadMockups({
        product_id: values.productType,
        sku_number: values.skuNumber,
        files: values.uploadedFiles
      });

      toast.success(`Mockups with SKU=${res.id} uploaded successfully`);

      onSubmit();
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  const stepContent = useMemo(() => {
    switch (step) {
      case CustomMockupWizardStep.PRODUCT_TYPE_SELECT:
        return <ProductTypeSelect />;
      case CustomMockupWizardStep.FILE_UPLOAD:
        return <MockupFileUpload />;
      case CustomMockupWizardStep.SETTINGS:
        return <CustomMockupSettings />;
      default:
        return <>not implemented</>;
    }
  }, [step, uploadedMockupFiles, onSubmit]);

  useEffect(() => {
    switch (step) {
      case CustomMockupWizardStep.PRODUCT_TYPE_SELECT:
        updateSchema(
          Yup.object().shape({
            productType: Yup.string().required("Please select product")
          })
        );
        break;
      case CustomMockupWizardStep.FILE_UPLOAD:
        updateSchema(
          Yup.object().shape({
            uploadedFiles: Yup.array().of(Yup.mixed()).min(1, "Please upload at least one file")
          })
        );
        break;
      case CustomMockupWizardStep.SETTINGS:
        updateSchema(
          Yup.object().shape({
            skuNumber: Yup.string().required("This field is required")
          })
        );
        break;
      default:
    }
  }, [step]);

  return (
    <div className="row">
      {loader && <Loader />}
      <div className="col-8 mx-auto">
        <div className="row">
          <div className="col-auto mx-auto">
            <WizardStepNavigation
              passedSteps={wizardPassedSteps}
              stepsData={CUSTOM_MOCKUP_WIZARD_STEPS_DATA}
              activeStep={step}
              onStepChange={handleStepChange}
            />
          </div>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmitHandler)}>
              <div className="col-12 mt-3">{stepContent}</div>
              <WizardStepControls
                smallTopOffset
                lastStepIsSubmit
                firstStep={CustomMockupWizardStep.PRODUCT_TYPE_SELECT}
                stepsMap={STEPS_MAP}
                activeStep={step}
                onMove={handleStepChange}
              />
            </form>
          </FormProvider>
        </div>
      </div>
    </div>
  );
};
