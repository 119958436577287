import React from "react";

import { Button, CardBody } from "reactstrap";

import { MockupType } from "../../../common/interfaces/mockup-wizard.interface";

interface Props {
  onTypeSelect: (type: MockupType) => void;
}

export const MockupTypeSelection: React.FC<Props> = ({ onTypeSelect }) => {
  return (
    <div className="row justify-content-center align-items-center h-100">
      <div className="index-cards">
        <div className="index-cards__inner">
          <div className="card">
            <CardBody>
              <h5 className="font-size-16 mt-0">AUTO Mockups</h5>
              <p className="card-text">We will generate mockups for you automatically based on your print files</p>
            </CardBody>
            <div className="card-button">
              <Button
                onClick={() => {
                  onTypeSelect(MockupType.AUTO);
                }}
                className="btn btn-info waves-effect waves-light"
              >
                Generate
              </Button>
            </div>
          </div>
          <div className="card">
            <CardBody>
              <h5 className="font-size-16 mt-0">Custom</h5>
              <p className="card-text">Upload your own mockups</p>
            </CardBody>
            <div className="card-button">
              <Button
                onClick={() => {
                  onTypeSelect(MockupType.CUSTOM);
                }}
                className="btn btn-success waves-effect waves-light"
              >
                Upload files
              </Button>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="col-md-6 col-xl-3"> */}
      {/*  <div className="card border h-100"> */}
      {/*    <CardBody className="h-100 d-flex flex-column"> */}
      {/*      <h5 className="font-size-16 mt-0">AUTO Mockups</h5> */}
      {/*      <p className="card-text">We will generate mockups for you automatically based on your print files</p> */}
      {/*      <Button */}
      {/*        color="success" */}
      {/*        className="mt-auto" */}
      {/*        onClick={() => { */}
      {/*          onTypeSelect(MockupType.AUTO); */}
      {/*        }} */}
      {/*      > */}
      {/*        Generate */}
      {/*      </Button> */}
      {/*    </CardBody> */}
      {/*  </div> */}
      {/* </div> */}
      {/* <div className="col-md-6 col-xl-3"> */}
      {/*  <div className="card border h-100"> */}
      {/*    <CardBody className="h-100 d-flex flex-column"> */}
      {/*      <h5 className="font-size-16 mt-0">Custom</h5> */}
      {/*      <p className="card-text">Upload your own mockups</p> */}
      {/*      <Button */}
      {/*        color="secondary" */}
      {/*        className="mt-auto" */}
      {/*        onClick={() => { */}
      {/*          onTypeSelect(MockupType.CUSTOM); */}
      {/*        }} */}
      {/*      > */}
      {/*        Upload mockups */}
      {/*      </Button> */}
      {/*    </CardBody> */}
      {/*  </div> */}
      {/* </div> */}
    </div>
  );
};
