import { ExtendedFile } from "./vendor.interface";

export enum MockupType {
  AUTO = "auto",
  CUSTOM = "custom"
}

export enum CustomMockupWizardStep {
  PRODUCT_TYPE_SELECT = "CMW:PRODUCT_TYPE_SELECT",
  FILE_UPLOAD = "CMW:FILE_UPLOAD",
  SETTINGS = "CMW:SETTINGS"
}

export enum AutoMockupWizardStep {
  PRODUCT_TYPE_SELECT = "AMW:PRODUCT_TYPE_SELECT",
  DESIGN_SELECT = "AMW:DESIGN_SELECT",
  SETTINGS = "AMW:SETTINGS",
  GENERATED_MOCKUPS = "AMW:GENERATED_MOCKUPS"
}

export const CUSTOM_MOCKUP_WIZARD_STEPS_DATA = [
  {
    title: "Product type",
    stepIdx: CustomMockupWizardStep.PRODUCT_TYPE_SELECT
  },
  {
    title: "File upload",
    stepIdx: CustomMockupWizardStep.FILE_UPLOAD
  },
  {
    title: "Settings",
    stepIdx: CustomMockupWizardStep.SETTINGS
  }
];

export const AUTO_MOCKUP_WIZARD_STEPS_DATA = [
  {
    title: "Product type",
    stepIdx: AutoMockupWizardStep.PRODUCT_TYPE_SELECT
  },
  {
    title: "Design",
    stepIdx: AutoMockupWizardStep.DESIGN_SELECT
  },
  {
    title: "Settings",
    stepIdx: AutoMockupWizardStep.SETTINGS
  }
];

export interface ModelOption {
  id: string;
  label: string;
}

export interface PhoneColorOption {
  id: string;
  label: string;
}

export interface MockupBackgroundOption {
  id: string;
  label: string;
  isImage?: boolean;
}

export type MockupWizardSubmitData = MockupWizardAutoSubmitData | MockupWizardCustomSubmitData;

export interface MockupWizardAutoSubmitData {
  selectedPhoneModels: ModelOption[];
  selectedPhoneColor: PhoneColorOption;
  selectedMockupBackgroundColor: MockupBackgroundOption;
  customMockupBackgroundImage: ExtendedFile | null;
  showNumbers: boolean;
}

export interface MockupWizardCustomSubmitData {
  images: ExtendedFile[];
}
