// import { IProductItem, ProductType } from "../common/interfaces/products.interface";
//
// export default {
//   top: [
//     {
//       image: "tough",
//       title: "Tough Phone Case",
//       price_usd: 15,
//       id: "1",
//       type: ProductType.PHONE_CASE,
//       material: {
//         id: "1",
//         title: "Material #1",
//         price_usd: 5,
//         image: "https://picsum.photos/200/200"
//       },
//       category: "electronic_accessories"
//     },
//     {
//       image: "clear",
//       title: "Clear Phone Case",
//       price_usd: 12,
//       id: "2",
//       type: ProductType.PHONE_CASE,
//       material: {
//         id: "1",
//         title: "Material #1",
//         price_usd: 5,
//         image: "https://picsum.photos/200/200"
//       },
//       category: "electronic_accessories"
//     },
//     {
//       image: "snap",
//       title: "Snap Phone case",
//       price_usd: 11,
//       id: "3",
//       type: ProductType.PHONE_CASE,
//       material: {
//         id: "1",
//         title: "Material #1",
//         price_usd: 5,
//         image: "https://picsum.photos/200/200"
//       },
//       category: "electronic_accessories"
//     },
//     {
//       image: "flexi",
//       title: "Flexi Phone case",
//       price_usd: 15,
//       id: "4",
//       type: ProductType.PHONE_CASE,
//       material: {
//         id: "1",
//         title: "Material #1",
//         price_usd: 5,
//         image: "https://picsum.photos/200/200"
//       },
//       category: "electronic_accessories"
//     }
//   ],
//   all: [
//     {
//       image: "clear",
//       title: "Clear Phone case",
//       price_usd: 15,
//       id: "2",
//       type: ProductType.PHONE_CASE,
//       description:
//         "What is a Gel Phone Case? TPU, which is short for thermoplastic polyurethane, is a material based on plastic and has properties such as elasticity, transparency, and strong resistance to oils and liquids. Gel phone cases are usually slim and lightweight, come in a range of colors, and have a matte or gloss finish. These cases can withstand bumps and scratches very well and, because of their elasticity, are very durable and will not crack or break. Gel cases will protect the phone on the back and sides of the device.",
//       material: {
//         id: "1",
//         title: "Material #1",
//         price_usd: 5,
//         image: "https://picsum.photos/200/200"
//       },
//       category: "electronic_accessories"
//     },
//     {
//       image: "flexi",
//       title: "Flexi Phone case",
//       price_usd: 15,
//       id: "4",
//       type: ProductType.PHONE_CASE,
//       description:
//         "What is a silicone phone case? Many people know silicone phone cases frm the ones that used to be made for very early phone models, just at the beginning of when phones became smart. They were very soft, a little bit sticky to the touch, and they gripped the surface they were placed on. They were very popular for a while. Modern silicone phone cases are typically made from ‘liquid silicone’. You may wonder what a liquid silicone phone case is. Liquid silicone is different from old soft silicone in that it is much harder and similar to a gel phone case in its elasticity. Typically, these cases are a matte finish, are light to the touch, and can resist fingerprints and dust. They cover the back and sides of the phone and protect them in the same way that a gel cases do. They are very popular due to the feel and finish they offer.",
//       material: {
//         id: "1",
//         title: "Material #1",
//         price_usd: 5,
//         image: "https://picsum.photos/200/200"
//       },
//       category: "electronic_accessories"
//     },
//     {
//       image: "tough",
//       title: "Tough Phone case",
//       price_usd: 15,
//       id: "1",
//       type: ProductType.PHONE_CASE,
//       description:
//         "Usually, these are tougher than regular hard or gel cases. These cases can come in a range of styles within themselves, and the word ‘tough’ is subjective as it depends on how tough you would like your phone case. You can determine this by how and where you will be using the phone. Many people in the construction industry or industries operating outdoors like more robust phone cases. Some tough cases provide just an extra-strong reinforced shell type of case, which is open, and others can include a complete tough shell that covers the front of the device as well. Usually, when marketed, the tough phone case providers market them as protected for military drop tests up to 15 feet, for example, along with IPS water protection of varying scales. Due to these factors, they are usually a bit heavier and bulkier than standard hard and gel phone cases. But they are a popular case style as evidenced by their ongoing sales.",
//       material: {
//         id: "1",
//         title: "Material #1",
//         price_usd: 5,
//         image: "https://picsum.photos/200/200"
//       },
//       category: "electronic_accessories"
//     },
//     {
//       image: "snap",
//       title: "Wallet Phone case",
//       price_usd: 15,
//       id: "5",
//       type: ProductType.PHONE_CASE,
//       description:
//         "These are a popular style that doubles as both a phone case and a wallet. They are usually made from leather, often in PU (polyurethane) leather, but also found in authentic leather. These cases have a polycarbonate insert that the phone can be clipped into and then a surrounding leather on the back that can be folded over the front of the phone.",
//       material: {
//         id: "1",
//         title: "Material #1",
//         price_usd: 5,
//         image: "https://picsum.photos/200/200"
//       },
//       category: "electronic_accessories"
//     },
//     {
//       image: "soft",
//       title: "Flip Phone case",
//       price_usd: 15,
//       id: "6",
//       type: ProductType.PHONE_CASE,
//       description:
//         "Flip phone cases are similar to a wallet style phone case. They can even be flip wallet cases or just a flip case without the wallet aspect. They usually have an inner polycarbonate shell, and the front flips down vertically from the top or the bottom, so it flips open essentially on the short edge. The draw of these cases is the look of them, in that they are a leather style. They are protective of the front, back, and sides of the devices, and usually come in a range of colors. They are not as popular as the wallet style case, however.",
//       material: {
//         id: "1",
//         title: "Material #1",
//         price_usd: 5,
//         image: "https://picsum.photos/200/200"
//       },
//       category: "electronic_accessories"
//     },
//     {
//       image: "snap",
//       title: "Snap Phone case",
//       price_usd: 11,
//       id: "3",
//       type: ProductType.PHONE_CASE,
//       material: {
//         id: "1",
//         title: "Material #1",
//         price_usd: 5,
//         image: "https://picsum.photos/200/200"
//       },
//       category: "electronic_accessories"
//     }
//   ]
// } as {
//   top: IProductItem[];
//   all: IProductItem[];
// };

import { IProductItem, ProductType } from "../common/interfaces/products.interface";

export default {
  top: [
    // {
    //   image: "tough",
    //   title: "Tough Phone Case",
    //   price_usd: 15,
    //   id: "1",
    //   type: ProductType.PHONE_CASE,
    //   material: {
    //     id: "1",
    //     title: "Material #1",
    //     price_usd: 5,
    //     image: "https://picsum.photos/200/200"
    //   },
    //   category: "electronic_accessories"
    // },
    // {
    //   image: "clear",
    //   title: "Clear Phone Case",
    //   price_usd: 12,
    //   id: "2",
    //   type: ProductType.PHONE_CASE,
    //   material: {
    //     id: "1",
    //     title: "Material #1",
    //     price_usd: 5,
    //     image: "https://picsum.photos/200/200"
    //   },
    //   category: "electronic_accessories"
    // },
    // {
    //   image: "snap",
    //   title: "Snap Phone case",
    //   price_usd: 11,
    //   id: "3",
    //   type: ProductType.PHONE_CASE,
    //   material: {
    //     id: "1",
    //     title: "Material #1",
    //     price_usd: 5,
    //     image: "https://picsum.photos/200/200"
    //   },
    //   category: "electronic_accessories"
    // },
    // {
    //   image: "flexi",
    //   title: "Flexi Phone case",
    //   price_usd: 15,
    //   id: "4",
    //   type: ProductType.PHONE_CASE,
    //   material: {
    //     id: "1",
    //     title: "Material #1",
    //     price_usd: 5,
    //     image: "https://picsum.photos/200/200"
    //   },
    //   category: "electronic_accessories"
    // }
  ],
  all: [
    {
      image: "/assets/phone-cases/tough.jpg",
      title: "Tough Phone case",
      price: 15,
      id: "1",
      type: ProductType.PHONE_CASE,
      description:
        "Usually, these are tougher than regular hard or gel cases. These cases can come in a range of styles within themselves, and the word ‘tough’ is subjective as it depends on how tough you would like your phone case. You can determine this by how and where you will be using the phone. Many people in the construction industry or industries operating outdoors like more robust phone cases. Some tough cases provide just an extra-strong reinforced shell type of case, which is open, and others can include a complete tough shell that covers the front of the device as well. Usually, when marketed, the tough phone case providers market them as protected for military drop tests up to 15 feet, for example, along with IPS water protection of varying scales. Due to these factors, they are usually a bit heavier and bulkier than standard hard and gel phone cases. But they are a popular case style as evidenced by their ongoing sales.",
      category: "electronic_accessories"
    },
    {
      image: "/assets/phone-cases/tough_magsafe.jpg",
      title: "Tough Mag Safe Phone case",
      price: 15,
      id: "2",
      type: ProductType.PHONE_CASE,
      description:
        "Usually, these are tougher than regular hard or gel cases. These cases can come in a range of styles within themselves, and the word ‘tough’ is subjective as it depends on how tough you would like your phone case. You can determine this by how and where you will be using the phone. Many people in the construction industry or industries operating outdoors like more robust phone cases. Some tough cases provide just an extra-strong reinforced shell type of case, which is open, and others can include a complete tough shell that covers the front of the device as well. Usually, when marketed, the tough phone case providers market them as protected for military drop tests up to 15 feet, for example, along with IPS water protection of varying scales. Due to these factors, they are usually a bit heavier and bulkier than standard hard and gel phone cases. But they are a popular case style as evidenced by their ongoing sales.",
      category: "electronic_accessories"
    },
    {
      image: "/assets/phone-cases/snap.jpg",
      title: "Snap Phone case",
      price: 11,
      id: "3",
      type: ProductType.PHONE_CASE,
      category: "electronic_accessories"
    },
    {
      image: "/assets/phone-cases/tpu.png",
      title: "TPU Phone case",
      price: 11,
      id: "4",
      type: ProductType.PHONE_CASE,
      category: "electronic_accessories"
    }
  ]
} as {
  top: any[];
  all: any[];
};
