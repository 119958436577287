import React, { useEffect, useMemo, useRef } from "react";

import classNames from "classnames";
import { useDispatch } from "react-redux";
import { Alert } from "reactstrap";

import { ORDER_INIT_NEW_ORDER } from "../../../store/wizards/orderSlice";

interface Props<T extends string> {
  activeStep: T;
  onMove: (step: T) => void;
  lastStepIsSubmit?: boolean;
  firstStep: T;
  canBeSubmitted?: boolean;
  stepsMap: Partial<Record<T, { next: T | null; previous: T | null }>>;
  smallTopOffset?: boolean;
}

export const WizardStepControls = <T extends string>({
  activeStep,
  onMove,
  firstStep,
  stepsMap,
  smallTopOffset,
  lastStepIsSubmit,
  canBeSubmitted
}: Props<T>) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const [buttonWidth, setButtonWidth] = React.useState(0);
  const shouldRenderPreviousButton = useMemo(() => {
    return activeStep !== firstStep;
  }, [activeStep]);

  const isLastStep = useMemo(() => {
    return !stepsMap[activeStep]?.next;
  }, [activeStep]);

  // listen for resize event

  const handleResize = () => {
    setButtonWidth(parentRef.current!.offsetWidth as number);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (parentRef.current) {
      handleResize();
    }
  }, [parentRef]);

  return (
    <div className="row">
      <div className="col-12" ref={parentRef}>
        <div
          className={classNames("wizard-step-controls button-items d-flex justify-content-between", {
            // "mt-5": !smallTopOffset,
            // "mt-3": smallTopOffset
          })}
          style={{
            width: buttonWidth
          }}
        >
          {shouldRenderPreviousButton && (
            <button
              type="button"
              className="btn btn-light waves-effect"
              onClick={() => {
                const current = stepsMap[activeStep];
                if (!current) return;
                if (current.previous) {
                  onMove(current.previous as T);
                }
              }}
            >
              <i className="me-2 mdi mdi-arrow-left" />
              Back
            </button>
          )}

          {lastStepIsSubmit && isLastStep && canBeSubmitted !== false && (
            <button type="submit" className="btn btn-success waves-effect waves-light">
              Submit
            </button>
          )}

          {/* {isLastStep && isOrderWizard && ( */}
          {/*  <button className="btn btn-primary waves-effect waves-light" type="submit"> */}
          {/*    Save */}
          {/*  </button> */}
          {/* )} */}

          {!isLastStep && (
            <button
              type="submit"
              className="btn btn-primary waves-effect waves-light ms-auto"
              // onClick={() => {
              //   const current = stepsMap[activeStep];
              //   if (!current) return;
              //   if (current.next) {
              //     if (validate && !validate()) {
              //       setIsValidationFailed(true);
              //       return;
              //     }
              //
              //     setIsValidationFailed(false);
              //     // onMove(current.next as T);
              //   }
              // }}
            >
              Next
              <i className="mdi mdi-arrow-right ms-2" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
