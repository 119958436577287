import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IProductAssetItem, IProductItem, IProductMaterial } from "../../common/interfaces/products.interface";

interface ProductWizardState {
  selectedProductType: IProductItem | null;
  selectedProductAsset: IProductAssetItem | null;
  selectedProductMaterial: IProductMaterial | null;
  productTitle: string;
  productDescription: string;
  productSkuNumber: string;
  productPrintFiles: [string, string][]; // base64, name
  productMockupFiles: [string, string][]; // base64, name
}

export const productWizardInitialState: ProductWizardState = {
  selectedProductType: null,
  selectedProductAsset: null,
  selectedProductMaterial: null,
  productTitle: "",
  productDescription: "",
  productSkuNumber: "",
  productPrintFiles: [],
  productMockupFiles: []
};

export const productWizardSlice = createSlice({
  name: "app",
  initialState: productWizardInitialState,
  reducers: {
    PRODUCT_SET_SELECTED_PRODUCT_TYPE: (state: ProductWizardState, action: PayloadAction<IProductItem | null>) => {
      state.selectedProductType = action.payload;
    },
    PRODUCT_SET_SELECTED_PRODUCT_ASSET: (
      state: ProductWizardState,
      action: PayloadAction<IProductAssetItem | null>
    ) => {
      state.selectedProductAsset = action.payload;
    },
    PRODUCT_SET_SELECTED_PRODUCT_MATERIAL: (
      state: ProductWizardState,
      action: PayloadAction<IProductMaterial | null>
    ) => {
      state.selectedProductMaterial = action.payload;
    },
    PRODUCT_SET_PRODUCT_TITLE: (state: ProductWizardState, action: PayloadAction<string>) => {
      state.productTitle = action.payload;
    },
    PRODUCT_SET_PRODUCT_DESCRIPTION: (state: ProductWizardState, action: PayloadAction<string>) => {
      state.productDescription = action.payload;
    },
    PRODUCT_SET_PRODUCT_SKU_NUMBER: (state: ProductWizardState, action: PayloadAction<string>) => {
      state.productSkuNumber = action.payload;
    },
    PRODUCT_SET_PRODUCT_PRINT_FILES: (state: ProductWizardState, action: PayloadAction<[string, string][]>) => {
      state.productPrintFiles = action.payload;
    },
    PRODUCT_SET_PRODUCT_MOCKUP_FILES: (state: ProductWizardState, action: PayloadAction<[string, string][]>) => {
      state.productMockupFiles = action.payload;
    }
  }
});

export const {
  PRODUCT_SET_SELECTED_PRODUCT_TYPE,
  PRODUCT_SET_SELECTED_PRODUCT_ASSET,
  PRODUCT_SET_SELECTED_PRODUCT_MATERIAL,
  PRODUCT_SET_PRODUCT_TITLE,
  PRODUCT_SET_PRODUCT_DESCRIPTION,
  PRODUCT_SET_PRODUCT_SKU_NUMBER,
  PRODUCT_SET_PRODUCT_PRINT_FILES,
  PRODUCT_SET_PRODUCT_MOCKUP_FILES
} = productWizardSlice.actions;

export default productWizardSlice.reducer;
