import React from "react";

// import { ORDER_INIT } from "../../store/wizards/orderSlice";

export const ProductDetailPage = () => {
  return <>todo</>;
  // const { id } = useParams();
  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  //
  // const product = productsMock.all.find((item) => item.id === id) as IProductItem;
  // const [selectedImage, setSelectedImage] = React.useState(getImageUrl(product.image));
  //
  // return (
  //   <>
  //     <Breadcrumb
  //       title={product.title}
  //       items={[
  //         {
  //           title: "Products"
  //         },
  //         {
  //           title: "Phone cases"
  //         },
  //         {
  //           title: product.title
  //         }
  //       ]}
  //     />
  //     <div className="product-detail">
  //       <div className="row d-flex justify-content-center">
  //         <div className="col-md-10">
  //           <div className="card">
  //             <div className="row">
  //               <div className="col-md-6">
  //                 <div className="images d-flex h-100 align-items-center justify-content-center p-3 flex-column">
  //                   <div className="text-center p-4">
  //                     <img alt="Product #1" src={selectedImage} width="250" />
  //                   </div>
  //                   <div className="thumbnail text-center">
  //                     <a
  //                       href="#"
  //                       onClick={() => {
  //                         setSelectedImage(getImageUrl(product.image));
  //                       }}
  //                     >
  //                       <img alt="Product #1" src={getImageUrl(product.image)} width="100" />
  //                     </a>
  //                     <a
  //                       href="#"
  //                       onClick={() => {
  //                         setSelectedImage(getImageUrl(product.image, true));
  //                       }}
  //                     >
  //                       <img alt="Product #1" src={getImageUrl(product.image, true)} width="100" />
  //                     </a>
  //                   </div>
  //                 </div>
  //               </div>
  //               <div className="col-md-6">
  //                 <div className="product p-4">
  //                   <div className="d-flex justify-content-between align-items-center">
  //                     <div className="d-flex align-items-center">
  //                       <Link to="/categories/phone_cases" className="btn btn-link p-0 text-decoration-none">
  //                         <i className="fa fa-arrow-left" /> <span className="ms-1">Back</span>
  //                       </Link>
  //                     </div>
  //                   </div>
  //                   <div className="mt-4 mb-3">
  //                     <h5 className="text-uppercase">{product.title}</h5>
  //                     <div className="price d-flex flex-row align-items-center">
  //                       <span className="act-price">${product.price_usd}</span>
  //                     </div>
  //                   </div>
  //                   <p className="about">{product.description}</p>
  //                   <div className="cart mt-5 align-items-center">
  //                     <button
  //                       className="btn btn-primary text-uppercase me-2 px-4"
  //                       onClick={() => {
  //                         // dispatch(ORDER_INIT(product));
  //                         navigate("/orders/create");
  //                       }}
  //                     >
  //                       Create order
  //                     </button>
  //                     <button className="btn btn-success text-uppercase px-4">Create listing</button>
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </>
  // );
};
