import React, { useEffect, useMemo } from "react";

import { FormProvider } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CardBody } from "reactstrap";
import * as Yup from "yup";
import { string } from "yup";

import {
  OrderInstance,
  OrderWizardInstanceStatus,
  OrderWizardStep,
  STEPS_DATA
} from "../../../common/interfaces/order-wizard.interface";
import { useCustomForm } from "../../../hooks/useCustomForm";
import { useAppSelector } from "../../../store/store";
import { ORDER_SET_WIZARD_ACTIVE_STEP } from "../../../store/wizards/orderSlice";
import { WizardStepControls } from "../common/WizardStepControls";
import { WizardStepNavigation } from "../common/WizardStepNavigation";
import { OrderCreationSummary } from "./OrderCreationSummary";
import { ProductDeliveryStep } from "./steps/ProductDeliveryStep";
import { ProductDesignStep } from "./steps/ProductDesignStep";
import { ProductSelectionStep } from "./steps/ProductSelectionStep";
import { ProductSettingsStep } from "./steps/ProductSettingsStep";

const STEPS_MAP = {
  [OrderWizardStep.ORDER_PRODUCT_SELECTION]: {
    next: OrderWizardStep.ORDER_PRODUCT_SETTINGS,
    previous: null
  },
  [OrderWizardStep.ORDER_PRODUCT_SETTINGS]: {
    next: OrderWizardStep.ORDER_DESIGN_SETTINGS,
    previous: OrderWizardStep.ORDER_PRODUCT_SELECTION
  },
  [OrderWizardStep.ORDER_DESIGN_SETTINGS]: {
    next: OrderWizardStep.ORDER_DELIVERY_SETTINGS,
    previous: OrderWizardStep.ORDER_PRODUCT_SETTINGS
  },
  [OrderWizardStep.ORDER_DELIVERY_SETTINGS]: {
    next: null,
    previous: OrderWizardStep.ORDER_DESIGN_SETTINGS
  }
};

export const OrderCreationWizard = () => {
  const dispatch = useDispatch();
  const methods = useCustomForm({
    schema: Yup.object().shape({})
  });

  const { handleSubmit, updateSchema, reset, getValues } = methods;

  const orderInstance = useAppSelector((state) => {
    const target = state.orderWizard.orders.find((e) => e.index === state.orderWizard.selectedIndex);
    return target as OrderInstance | null;
  });

  const activeStep = useMemo(() => {
    return orderInstance?.activeWizardStep as OrderWizardStep;
  }, [orderInstance]);

  const stepContent = useMemo(() => {
    // if (orderInstance.status === OrderWizardInstanceStatus.SUBMITTED) {
    //   return <ProductSummaryStep />;
    // }
    switch (activeStep) {
      case OrderWizardStep.ORDER_PRODUCT_SELECTION:
        return <ProductSelectionStep />;
      case OrderWizardStep.ORDER_PRODUCT_SETTINGS:
        return <ProductSettingsStep />;
      case OrderWizardStep.ORDER_DESIGN_SETTINGS:
        return <ProductDesignStep />;
      case OrderWizardStep.ORDER_DELIVERY_SETTINGS:
        return <ProductDeliveryStep />;
      default:
        return <>Not implemented</>;
    }
  }, [activeStep, orderInstance]);

  const onSubmit = () => {
    // @ts-ignore
    const current = STEPS_MAP[activeStep];
    if (!current) return;
    if (current.next) {
      dispatch(ORDER_SET_WIZARD_ACTIVE_STEP(current.next));
    } else {
      // reset();
    }
    // onMove(current.next as T);
  };

  const handleActiveStepChange = (stepIdx: OrderWizardStep) => {
    dispatch(ORDER_SET_WIZARD_ACTIVE_STEP(stepIdx));
  };

  useEffect(() => {
    switch (activeStep) {
      case OrderWizardStep.ORDER_PRODUCT_SELECTION:
        updateSchema(
          Yup.object().shape({
            productType: Yup.string().required("Please select product")
          })
        );
        break;
      case OrderWizardStep.ORDER_PRODUCT_SETTINGS:
        updateSchema(
          Yup.object().shape({
            productAssetType: Yup.string().required("This field is required")
          })
        );
        break;
      case OrderWizardStep.ORDER_DESIGN_SETTINGS:
        updateSchema(
          Yup.object().shape({
            selectedDesign: Yup.string().required("This field is required")
          })
        );
        break;
      case OrderWizardStep.ORDER_DELIVERY_SETTINGS:
        updateSchema(
          Yup.object().shape({
            fullName: string().required("This field is required").max(35, "Max 30 characters"),
            phone: string(),
            addressLine1: string().max(40, "Max 40 characters").required("This field is required"),
            addressLine2: string()
              .max(40, "Max 40 characters")
              .test("addressLine2", "Invalid address", function (value) {
                if (value) {
                  const schema = Yup.string().matches(/^[a-zA-Z0-9].*/, "Invalid address");
                  return schema.isValidSync(value);
                }
                return true;
              }),
            city: string().required("This field is required"),
            zipCode: string().required("This field is required"),
            country: string().required("This field is required")
          })
        );
        break;
      default:
    }
  }, [activeStep]);

  const wizardPassedSteps = useMemo(() => {
    const steps = [];

    if (!orderInstance) return [];

    if (orderInstance?.selectedProductType && activeStep !== OrderWizardStep.ORDER_PRODUCT_SELECTION) {
      steps.push(OrderWizardStep.ORDER_PRODUCT_SELECTION);
    }

    if (orderInstance?.selectedProductAsset && activeStep !== OrderWizardStep.ORDER_PRODUCT_SETTINGS) {
      steps.push(OrderWizardStep.ORDER_PRODUCT_SETTINGS);
    }

    if (orderInstance?.selectedDesign && activeStep !== OrderWizardStep.ORDER_DESIGN_SETTINGS) {
      steps.push(OrderWizardStep.ORDER_DESIGN_SETTINGS);
    }

    return steps;
  }, [orderInstance]);

  return (
    <div className="d-flex flex-column">
      {orderInstance ? (
        <>
          <div className="wizard-step">
            <div className="row">
              <div className="column column--primary col-8 col-xxl-9">
                <div className="h-100">
                  {/* <OrderSelectionBar /> */}
                  <WizardStepNavigation
                    stepsData={STEPS_DATA}
                    activeStep={activeStep}
                    onStepChange={handleActiveStepChange}
                    passedSteps={wizardPassedSteps}
                  />
                  {/* <div className="card border mb-0"> */}
                  {/*  <CardBody> */}
                  <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      {stepContent}
                      {/* {orderInstance?.status !== OrderWizardInstanceStatus.SUBMITTED ? ( */}
                      <WizardStepControls
                        firstStep={OrderWizardStep.ORDER_PRODUCT_SELECTION}
                        stepsMap={STEPS_MAP}
                        activeStep={activeStep}
                        onMove={handleActiveStepChange}
                      />
                      {/* ) : ( */}
                      {/*  "" */}
                      {/* )} */}
                    </form>
                  </FormProvider>
                  {/*  </CardBody> */}
                  {/* </div> */}
                </div>
              </div>
              <div className="column column--secondary col-4 col-xxl-3">
                <OrderCreationSummary />
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};
