import { IProductAssetItem, ProductAssetType } from "../common/interfaces/products.interface";

export default [
  {
    label: "Pixel 5A/5G",
    value: "Pixel5A5G",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "Pixel 6",
    value: "Pixel6",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "Pixel 6A",
    value: "Pixel6A",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "Pixel 6 Pro",
    value: "Pixel6Pro",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "Pixel 7",
    value: "Pixel7",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "Pixel 7 Pro",
    value: "Pixel7Pro",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "Samsung A13 5G",
    value: "SamsungA135G",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "Samsung A14 4G/5G",
    value: "SamsungA144G5G",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "Samsung A23",
    value: "SamsungA23",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "Samsung A32 4G",
    value: "SamsungA324G",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "Samsung A33 5G",
    value: "SamsungA335G",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "Samsung A34",
    value: "SamsungA34",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "Samsung A40",
    value: "SamsungA40",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "Samsung A51/M40S",
    value: "SamsungA51M40S",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "Samsung A52",
    value: "SamsungA52",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "Samsung A53 5G",
    value: "SamsungA535G",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "Samsung A54",
    value: "SamsungA54",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "Samsung A73 5G",
    value: "SamsungA735G",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "Samsung M33 5G",
    value: "SamsungM335G",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "Samsung Note 10",
    value: "SamsungNote10",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "Samsung Note 10 Lite",
    value: "SamsungNote10Lite",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "Samsung Note 10 Plus",
    value: "SamsungNote10Plus",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "Samsung Note 20",
    value: "SamsungNote20",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "Samsung Note 20 Ultra",
    value: "SamsungNote20Ultra",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "Samsung Note 9",
    value: "SamsungNote9",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "Samsung S10",
    value: "SamsungS10",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "Samsung S10 LITE",
    value: "SamsungS10LITE",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "Samsung S10 Plus",
    value: "SamsungS10Plus",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "Samsung S10e",
    value: "SamsungS10e",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "Samsung S20",
    value: "SamsungS20",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "Samsung S20 Plus",
    value: "SamsungS20Plus",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "Samsung S20 Ultra",
    value: "SamsungS20Ultra",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "Samsung S21",
    value: "SamsungS21",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "Samsung S21FE",
    value: "SamsungS21FE",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "Samsung S21 Plus",
    value: "SamsungS21Plus",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "Samsung S21 Ultra",
    value: "SamsungS21Ultra",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "Samsung S22",
    value: "SamsungS22",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "Samsung S22 Plus",
    value: "SamsungS22Plus",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "Samsung S22 Ultra",
    value: "SamsungS22Ultra",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "Samsung S23 5G",
    value: "SamsungS235G",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "Samsung S23 Plus",
    value: "SamsungS23Plus",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "Samsung S23 Ultra",
    value: "SamsungS23Ultra",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "Samsung S7",
    value: "SamsungS7",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "Samsung S7 Edge",
    value: "SamsungS7Edge",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "Samsung S8",
    value: "SamsungS8",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "Samsung S8 Plus",
    value: "SamsungS8Plus",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "Samsung S9",
    value: "SamsungS9",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "Samsung S9 Plus",
    value: "SamsungS9Plus",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "iPhone 11",
    value: "iPhone11",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "iPhone 11 Pro",
    value: "iPhone11Pro",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "iPhone 11 Pro Max",
    value: "iPhone11ProMax",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "iPhone 12/12 Pro",
    value: "iPhone1212Pro",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "iPhone 12/13 Mini",
    value: "iPhone1213Mini",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "iPhone 12 Pro Max",
    value: "iPhone12ProMax",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "iPhone 13",
    value: "iPhone13",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "iPhone 13 Mini",
    value: "iPhone13Mini",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "iPhone 13 Pro",
    value: "iPhone13Pro",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "iPhone 13 Pro Max",
    value: "iPhone13ProMax",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "iPhone 14",
    value: "iPhone14",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "iPhone 14 Plus",
    value: "iPhone14Plus",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "iPhone 14 Pro",
    value: "iPhone14Pro",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "iPhone 14 Pro Max",
    value: "iPhone14ProMax",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "iPhone 5/5s",
    value: "iPhone55s",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "iPhone 6/6s",
    value: "iPhone66s",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "iPhone 6 Plus/6S Plus",
    value: "iPhone6Plus6SPlus",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "iPhone 7/8/SE/SE 2020",
    value: "iPhone78SESE2020",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "iPhone 7 Plus/8 Plus",
    value: "iPhone7Plus8Plus",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "iPhone XR",
    value: "iPhoneXR",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "iPhone XS Max",
    value: "iPhoneXSMax",
    type: ProductAssetType.PHONE_CASE
  },
  {
    label: "iPhone X/XS",
    value: "iPhoneXXS",
    type: ProductAssetType.PHONE_CASE
  }
] as any[];
