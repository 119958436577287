import React, { useCallback, useEffect, useState } from "react";

import BootstrapTable, { TableChangeState } from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";
// @ts-ignore
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, Col, Row } from "reactstrap";

import { CLEAR_USER_TABLE_FILTER_VALUES, USER_TABLE_COLUMNS } from "../../../common/constants/users-table.constant";
import { IPaginationMeta } from "../../../common/interfaces/api.interface";
import { IUserItemWithCustomer } from "../../../common/interfaces/backoffice.interface";
import { Loader } from "../../../components/common/Loader";
import { useServiceContainer } from "../../../hooks/useServiceContainer";

export const UsersListPage = () => {
  const [items, setItems] = useState<IUserItemWithCustomer[]>([]);
  const [page, setPage] = useState(1);
  const [paginationMeta, setPaginationMeta] = useState<null | IPaginationMeta>(null);
  const [loader, setLoader] = useState(false);
  const { backofficeService } = useServiceContainer();
  const [filters, setFilters] = useState<any>({});
  const [sortSettings, setSortSettings] = useState<any>({});

  const toggleUser = useCallback(
    async (userId: number, state: boolean) => {
      setLoader(true);
      try {
        await backofficeService.updateUserById(userId, { is_deleted: state ? 0 : 1 });
        setItems(
          items.map((item) => {
            if (item.id === userId) {
              item.is_deleted = state ? 0 : 1;
            }
            return item;
          })
        );
      } catch (e) {
        console.error(e);
      } finally {
        setLoader(false);
      }
    },
    [items]
  );

  const columns = [
    ...USER_TABLE_COLUMNS,
    {
      text: "Actions",
      dataField: "is_deleted",
      formatter: (cellContent: any, row: IUserItemWithCustomer) => {
        return (
          <>
            <Button
              size="sm"
              color={row.is_deleted ? "success" : "danger"}
              onClick={() => {
                toggleUser(row.id, row.is_deleted === 1).then(() => null);
              }}
            >
              {row.is_deleted ? "Activate" : "Deactivate"}
            </Button>

            <Link className="btn btn-primary btn-sm ms-2" color="primary" to={`/backoffice/users/${row.id}/edit`}>
              Edit
            </Link>
          </>
        );
      }
    }
  ];

  const fetchList = async (selectedPage: number, _filters: Record<string, string>, _sort: Record<string, string>) => {
    setLoader(true);
    try {
      const res = await backofficeService.getUsersList(selectedPage, _filters, _sort);

      setItems(res.items);
      setPaginationMeta(res.meta);
    } catch (e) {
      console.error(e);
    } finally {
      setLoader(false);
    }
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc"
    }
  ] as any;

  const pageOptions = {
    sizePerPage: 20,
    page,
    totalSize: paginationMeta?.total || 0,
    custom: true
  };

  const onTableChange = (event: string, data: TableChangeState<any>) => {
    switch (event) {
      case "pagination":
        setPage(data.page);
        break;
      case "filter":
        setFilters(
          Object.fromEntries(Object.entries(data.filters).map(([column, value]) => [column, value.filterVal]))
        );
        break;
      case "sort":
        setSortSettings({
          [data.sortField]: data.sortOrder
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    fetchList(page, filters, sortSettings).then(() => null);
  }, [page, filters, sortSettings]);

  return (
    <div className="data-table">
      <Card className="border">
        {loader && <Loader />}
        <CardBody>
          <PaginationProvider pagination={paginationFactory(pageOptions)}>
            {({ paginationProps, paginationTableProps }: any) => (
              <ToolkitProvider keyField="id" columns={columns} data={items} search>
                {(toolkitProps: any) => {
                  return (
                    <>
                      <Row className="mb-2">
                        <Col xl="auto" className="d-flex">
                          <div className="d-inline-flex align-items-center me-3">
                            <Button
                              color="primary"
                              onClick={() => {
                                CLEAR_USER_TABLE_FILTER_VALUES();
                              }}
                            >
                              Clear filters
                            </Button>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12">
                          <div className="table-responsive">
                            <BootstrapTable
                              bootstrap4
                              remote
                              onTableChange={onTableChange}
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              filter={filterFactory()}
                              classes="table align-middle table-nowrap table-sm"
                              headerWrapperClasses="thead-light"
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                              data={items}
                              keyField="id"
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row className="align-items-md-center mt-30">
                        <Col className="inner-custom-pagination d-flex">
                          <div className="text-md-right ms-auto">
                            <PaginationListStandalone {...paginationProps} />
                          </div>
                        </Col>
                      </Row>
                    </>
                  );
                }}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        </CardBody>
      </Card>
    </div>
  );
};
