import React, { useMemo, useState } from "react";

import classNames from "classnames";
import BootstrapTable from "react-bootstrap-table-next";
// @ts-ignore
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { Col, Row } from "reactstrap";

import {
  ICartOrder,
  ICartOrderDesign,
  ICartOrderProduct,
  ICartOrderProductAsset
} from "../../common/interfaces/orders.interface";
import { getImageUrl } from "../../common/utils/formatter.util";

export const useCartTableTableExpandControl = (options: { items: ICartOrder[] }) => {
  const [expandedRows, setExpandedRows] = useState<number[]>([]);

  const onRowExpand = (row: ICartOrder, isExpand: boolean) => {
    if (isExpand) {
      setExpandedRows([row.salesOrderId]);
    } else {
      setExpandedRows([]);
    }
  };

  const expandableRowColumns = useMemo(() => {
    return [
      {
        dataField: "product",
        text: "Product",
        formatter: (cellContent: ICartOrderProduct) => {
          return <>{cellContent.name}</>;
        }
      },
      {
        dataField: "productAsset",
        text: "Asset",
        formatter: (cellContent: ICartOrderProductAsset) => {
          return <>{cellContent.title}</>;
        }
      },
      {
        dataField: "design",
        text: "Print file",
        formatter: (cellContent: ICartOrderDesign) => {
          return (
            <>
              <a href={getImageUrl(cellContent.print_file)} target="_blank" rel="noreferrer">
                <img src={getImageUrl(cellContent.print_file)} alt="Print file" />
              </a>
            </>
          );
        }
      },
      {
        dataField: "count",
        text: "Count",
        formatter: (cellContent: number) => {
          return cellContent;
        }
      },
      {
        dataField: "giftMessage",
        text: "Gift message",
        formatter: (cellContent: string | null) => {
          return cellContent ?? "-";
        }
      },
      {
        dataField: "includePremiumBag",
        text: "Premium bag",
        formatter: (cellContent: boolean) => {
          return (
            <span
              className={classNames("badge", {
                "bg-success": cellContent,
                "bg-danger": !cellContent
              })}
            >
              {cellContent ? "Yes" : "No"}
            </span>
          );
        }
      }
    ];
  }, []);

  const expandRowData = {
    renderer: (row: ICartOrder) => {
      return (
        <ToolkitProvider keyField="id" columns={expandableRowColumns} data={row.products}>
          {(toolkitProps: any) => (
            <>
              <Row>
                <Col xl="12">
                  <div className="table-responsive">
                    <BootstrapTable
                      bootstrap4
                      // onTableChange={onTableChange}
                      bordered={false}
                      striped={false}
                      classes="table align-middle table-nowrap table-sm"
                      headerWrapperClasses="thead-light"
                      {...toolkitProps.baseProps}
                      keyField="id"
                    />
                  </div>
                </Col>
              </Row>
            </>
          )}
        </ToolkitProvider>
      );
    },
    showExpandColumn: true,
    expanded: expandedRows,
    className: "order-management-table-expanded-row",
    onExpand: onRowExpand,
    expandHeaderColumnRenderer: () => null,
    expandColumnRenderer: (data: { rowKey: string; expanded: boolean; expandable: boolean }) => {
      console.log(data);
      if (!data.expandable) return "";

      const item = options.items.find((e) => e.salesOrderId === +data.rowKey);

      if (!item) return "";

      return (
        <span className="react-boostrap-table-expandable-row-control">
          {data.expanded ? "-" : "+"}({item.products.length})
        </span>
      );
    }
  };

  return {
    expandedRows,
    expandRowData,
    onRowExpand
  };
};
