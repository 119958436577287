export enum ProductWizardStep {
  PRODUCT_SELECTION = "ProductWizard::selection",
  PRODUCT_SETTINGS = "ProductWizard::settings",
  PRODUCT_META = "ProductWizard::meta",
  PRODUCT_DESIGN = "ProductWizard::design",
  PRODUCT_SUMMARY = "ProductWizard::summary",
  PRODUCT_INTEGRATIONS = "ProductWizard::integrations"
}

export const STEPS_DATA = [
  {
    title: "Selection",
    stepIdx: ProductWizardStep.PRODUCT_SELECTION
  },
  {
    title: "Settings",
    stepIdx: ProductWizardStep.PRODUCT_SETTINGS
  },
  {
    title: "Details",
    stepIdx: ProductWizardStep.PRODUCT_META
  },
  {
    title: "Design",
    stepIdx: ProductWizardStep.PRODUCT_DESIGN
  },
  {
    title: "Summary",
    stepIdx: ProductWizardStep.PRODUCT_SUMMARY
  }
];
