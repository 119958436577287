import React, { useMemo } from "react";

import classNames from "classnames";
import { Label } from "reactstrap";

import { makeId } from "../../../common/utils/random.util";
import { TooltipIcon } from "../TooltipIcon";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: any;
  tooltip?: string;
}

export const CheckboxFieldInput = React.forwardRef<HTMLInputElement, Props>(
  ({ tooltip, label, error, ...props }, ref: any) => {
    const tooltipId = useMemo(() => {
      if (!tooltip) return "";
      return makeId(7);
    }, [tooltip]);

    return (
      <div className="form-check mb-1 mx-1">
        <Label>
          <input
            type="checkbox"
            ref={ref}
            className={classNames("form-check-input", { "is-invalid": !!error })}
            {...props}
          />
          {label}

          {tooltip && (
            <span className="ms-1">
              <TooltipIcon id={tooltipId} content={tooltip} />
            </span>
          )}
        </Label>

        {error ? <div className="invalid-feedback d-block">{error}</div> : ""}
      </div>
    );
  }
);
