import React from "react";

import classNames from "classnames";
import Select, { SingleValue } from "react-select";
import { Label } from "reactstrap";

export interface SelectOption {
  label: string;
  value: string;
  disabled?: boolean;
}

interface Props<T> {
  value?: T | null;
  onChange?: (data: T) => void;
  options: T[];
  label?: string;
  required?: boolean;
  error?: any;
  [key: string]: any;
}

export const SelectField = <T extends SelectOption>({
  options,
  required,
  value,
  onChange,
  label,
  error,
  ...rest
}: Props<T>) => {
  return (
    <div className={classNames("mb-3 select-field", { "is-invalid": !!error })}>
      {label ? (
        <Label
          className={classNames({
            required
          })}
        >
          {label}
        </Label>
      ) : (
        ""
      )}
      <Select
        value={value}
        {...rest}
        onChange={(data) => {
          if (onChange) {
            onChange(data as T);
          }
        }}
        isOptionDisabled={(option) => option.disabled ?? false}
        options={options as any}
        classNamePrefix="select2-selection"
      />
      {error ? <div className="invalid-feedback d-block">{error}</div> : ""}
    </div>
  );
};
