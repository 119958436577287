import React, { useEffect, useMemo, useState } from "react";

import { useNavigate } from "react-router-dom";
import { Modal } from "reactstrap";

import { MockupType } from "../../../common/interfaces/mockup-wizard.interface";
import { ExtendedFile } from "../../../common/interfaces/vendor.interface";
import { useAppDispatch } from "../../../store/store";
import { ASSETS_RESET_SELECTED } from "../../../store/wizards/productAssetsSlice";
import { DESIGNS_RESET_SELECTED } from "../../../store/wizards/productDesignsSlice";
import { PRODUCT_TYPES_RESET_SELECTED } from "../../../store/wizards/productTypesSlice";
import { AutoMockupWizard } from "./AutoMockup/AutoMockupWizard";
import { CustomMockupWizard } from "./CustomMockup/CustomMockupWizard";
import { MockupTypeSelection } from "./TypeSelection";

interface Props {
  show: boolean;
  toggle: () => void;
  onSubmit: (data: ExtendedFile[]) => void;
}

export const GenerateMockupModal: React.FC<Props> = ({ show, toggle, onSubmit }) => {
  const [selectedType, setSelectedType] = useState<MockupType | null>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const stepContent = useMemo(() => {
    if (selectedType) {
      switch (selectedType) {
        case MockupType.AUTO:
          return (
            <AutoMockupWizard
              onReset={() => {
                resetModalState();
              }}
              onSubmit={() => {
                toggle();
                navigate("/mockups/list");
              }}
            />
          );
        case MockupType.CUSTOM:
          return (
            <CustomMockupWizard
              onSubmit={() => {
                toggle();
                navigate("/mockups/list");
              }}
            />
          );
        default:
          return "not implemented";
      }
    }

    return (
      <MockupTypeSelection
        onTypeSelect={(type) => {
          setSelectedType(type);
        }}
      />
    );
  }, [selectedType, onSubmit]);

  const resetModalState = () => {
    dispatch(ASSETS_RESET_SELECTED());
    dispatch(PRODUCT_TYPES_RESET_SELECTED());
    dispatch(DESIGNS_RESET_SELECTED());
  };

  useEffect(() => {
    if (!show) {
      resetModalState();
    }
  }, [show]);

  return (
    <Modal fade={false} isOpen={show} toggle={toggle} centered size="xl" className="modal-fullscreen">
      <div className="modal-header">
        <h5 className="modal-title mt-0">Mockup generator</h5>
        <button
          type="button"
          onClick={() => {
            toggle();
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body modal-body--gray">
        <div className="h-100">{stepContent}</div>
      </div>
    </Modal>
  );
};
