import React, { createContext } from "react";

import AuthApiService from "../services/api/AuthApiService";
import BackofficeApiService from "../services/api/BackofficeApiService";
import CommonApiService from "../services/api/CommonApiService";
import MockupApiService from "../services/api/MockupApiService";
import OrdersApiService from "../services/api/OrdersApiService";

export type ServiceContainer = {
  authService: typeof AuthApiService;
  ordersService: typeof OrdersApiService;
  mockupService: typeof MockupApiService;
  backofficeService: typeof BackofficeApiService;
  commonService: typeof CommonApiService;
};

export const ServiceContainerContext = createContext<ServiceContainer | null>(null);

type ServiceContainerProviderProps = {
  children: React.ReactNode;
};

export const ServiceContainerProvider: React.FC<ServiceContainerProviderProps> = ({ children }) => {
  const serviceContainer: ServiceContainer = {
    authService: AuthApiService,
    ordersService: OrdersApiService,
    mockupService: MockupApiService,
    backofficeService: BackofficeApiService,
    commonService: CommonApiService
  };

  return <ServiceContainerContext.Provider value={serviceContainer}>{children}</ServiceContainerContext.Provider>;
};
