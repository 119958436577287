import React, { useCallback, useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { Button, Card, CardBody, Col, Row, Table } from "reactstrap";
import sanitize from "sanitize-html";

import { IProductItem } from "../../../common/interfaces/products.interface";
import { showConfirmationModal } from "../../../common/utils/confirm.util";
import { getImageUrl } from "../../../common/utils/formatter.util";
import { CreateProductModal } from "../../../components/backoffice/modals/CreateProductModal";
import { Loader } from "../../../components/common/Loader";
import { useServiceContainer } from "../../../hooks/useServiceContainer";

export const ProductListPage = () => {
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState<IProductItem[]>([]);
  const [showCreateModal, setShowCreateModal] = useState(false);

  const { backofficeService } = useServiceContainer();

  const toggleStatus = useCallback(
    async (productId: number, state: number) => {
      setLoader(true);
      try {
        await backofficeService.updateProductById(productId, { status: state });
        setData(
          data.map((item) => {
            if (item.id === productId) {
              item.status = state;
            }
            return item;
          })
        );
      } catch (e) {
        console.error(e);
      } finally {
        setLoader(false);
      }
    },
    [data]
  );

  const duplicateProduct = useCallback(async (productId: number) => {
    setLoader(true);
    try {
      await backofficeService.duplicateProductById(productId);
      await fetchList();
    } catch (e) {
      console.error(e);
    } finally {
      setLoader(false);
    }
  }, []);

  const fetchList = async () => {
    const res = await backofficeService.getProductList();

    setData(res);
    setLoader(false);
  };

  useEffect(() => {
    fetchList().then(() => null);
  }, []);

  return (
    <Row>
      {loader && <Loader />}
      <Col md={12}>
        <Card>
          <CardBody>
            <button
              type="button"
              className="btn btn-success waves-effect w-100 ms-3"
              onClick={() => {
                setShowCreateModal(true);
              }}
            >
              Create new product
            </button>
          </CardBody>
        </Card>
      </Col>
      <Col md={12}>
        <Card>
          <CardBody>
            <div className="table-responsive">
              <Table className="table mb-0">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Title</th>
                    <th>Description</th>
                    <th>Image</th>
                    <th>Category</th>
                    <th>Type</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item) => (
                    <tr key={item.id}>
                      <td>{item.id}</td>
                      <td>{item.title}</td>
                      <td
                        dangerouslySetInnerHTML={{
                          __html: sanitize(item.description || "")
                        }}
                      />
                      <td>
                        <a href={getImageUrl(item.image)} target="_blank" rel="noreferrer">
                          <img
                            style={{
                              width: 70
                            }}
                            src={getImageUrl(item.image)}
                            alt={item.title}
                          />
                        </a>
                      </td>
                      <td>{item.category}</td>
                      <td>{item.type}</td>
                      <td>
                        <div className="d-flex flex-nowrap">
                          <Button
                            size="sm"
                            color={!item.status ? "success" : "danger"}
                            onClick={() => {
                              toggleStatus(item.id, item.status === 1 ? 0 : 1).then(() => null);
                            }}
                          >
                            {!item.status ? "Activate" : "Deactivate"}
                          </Button>
                          <Button
                            size="sm"
                            color="warning"
                            className="ms-2"
                            onClick={() => {
                              showConfirmationModal({
                                title: "Are you sure?",
                                message: "Do you really want to duplicate this product?",
                                onConfirm: async () => {
                                  await duplicateProduct(item.id);
                                },
                                onCancel: () => {}
                              });
                            }}
                          >
                            Duplicate
                          </Button>
                          <Link
                            className="btn btn-primary btn-sm ms-2"
                            color="primary"
                            to={`/backoffice/products/${item.id}/edit`}
                          >
                            Edit
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </Col>
      <CreateProductModal
        show={showCreateModal}
        toggle={() => {
          setShowCreateModal(false);
          fetchList().then(() => null);
        }}
      />
    </Row>
  );
};
