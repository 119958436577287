import React from "react";

import classNames from "classnames";
import { Tooltip } from "reactstrap";

import { TooltipIcon } from "../../common/TooltipIcon";

interface Props {
  children: any;
  error?: any;
  className?: string;
}

export const WizardScrollableContainer: React.FC<Props> = ({ className, children, error }) => {
  return (
    <div
      className={classNames("wizard-step__scrollable-container", className, {
        "wizard-step__scrollable-container--invalid": !!error
      })}
    >
      <div className={classNames("wizard-step__scrollable-container__inner")}>{children}</div>
      {error && (
        <div className="wizard-step__scrollable-container__tooltip">
          <TooltipIcon id="scrollable-container-tt" error content={error} />
        </div>
      )}
    </div>
  );
};
