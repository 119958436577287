import React, { useEffect, useMemo, useState } from "react";

import { FormProvider } from "react-hook-form";
import { useDispatch } from "react-redux";
import { CardBody } from "reactstrap";
import * as Yup from "yup";

import { PRODUCT_PRINT_FILES_LIMITATIONS } from "../../../common/constants/product.constant";
import { ProductWizardStep, STEPS_DATA } from "../../../common/interfaces/product-wizard.interface";
import { useCustomForm } from "../../../hooks/useCustomForm";
import { PageTitle } from "../../common/titles/PageTitle";
import { WizardStepControls } from "../common/WizardStepControls";
import { WizardStepNavigation } from "../common/WizardStepNavigation";
import { ProductDesignStep } from "./steps/ProductDesignStep";
import { ProductMetaStep } from "./steps/ProductMetaStep";
import { ProductSelectionStep } from "./steps/ProductSelectionStep";
import { ProductSettingsStep } from "./steps/ProductSettingsStep";
import { ProductSummaryStep } from "./steps/ProductSummaryStep";

const STEPS_MAP = {
  [ProductWizardStep.PRODUCT_SELECTION]: {
    next: ProductWizardStep.PRODUCT_SETTINGS,
    previous: null
  },
  [ProductWizardStep.PRODUCT_SETTINGS]: {
    next: ProductWizardStep.PRODUCT_META,
    previous: ProductWizardStep.PRODUCT_SELECTION
  },
  [ProductWizardStep.PRODUCT_META]: {
    next: ProductWizardStep.PRODUCT_DESIGN,
    previous: ProductWizardStep.PRODUCT_SETTINGS
  },
  [ProductWizardStep.PRODUCT_DESIGN]: {
    next: null,
    previous: ProductWizardStep.PRODUCT_META
  }
};

export const ProductCreationWizard = () => {
  const methods = useCustomForm({
    schema: Yup.object().shape({})
  });

  const { handleSubmit, updateSchema, getValues } = methods;

  const [activeStep, setActiveStep] = useState<ProductWizardStep>(ProductWizardStep.PRODUCT_SELECTION);

  const stepContent = useMemo(() => {
    switch (activeStep) {
      case ProductWizardStep.PRODUCT_SELECTION:
        return <ProductSelectionStep />;
      case ProductWizardStep.PRODUCT_SETTINGS:
        return <ProductSettingsStep />;
      case ProductWizardStep.PRODUCT_META:
        return <ProductMetaStep />;
      case ProductWizardStep.PRODUCT_DESIGN:
        return <ProductDesignStep />;
      case ProductWizardStep.PRODUCT_SUMMARY:
        return <ProductSummaryStep />;
      default:
        return <>Not implemented</>;
    }
  }, [activeStep]);

  const handleActiveStepChange = (stepIdx: ProductWizardStep) => {
    setActiveStep(stepIdx);
  };

  const onSubmit = () => {
    // @ts-ignore
    const current = STEPS_MAP[activeStep];
    if (!current) return;
    if (current.next) {
      setActiveStep(current.next);
    }
    // onMove(current.next as T);
  };

  useEffect(() => {
    switch (activeStep) {
      case ProductWizardStep.PRODUCT_SELECTION:
        updateSchema(
          Yup.object().shape({
            productType: Yup.string().required("This field is required")
          })
        );
        break;
      case ProductWizardStep.PRODUCT_SETTINGS:
        updateSchema(
          Yup.object().shape({
            productAssetType: Yup.string().required("This field is required"),
            productMaterial: Yup.string().required("This field is required")
          })
        );
        break;
      case ProductWizardStep.PRODUCT_META:
        updateSchema(
          Yup.object().shape({
            title: Yup.string().required("This field is required"),
            description: Yup.string().required("This field is required"),
            skuNumber: Yup.string().required("This field is required")
          })
        );
        break;
      case ProductWizardStep.PRODUCT_DESIGN:
        updateSchema(
          Yup.object().shape({
            printFiles: Yup.string().oneOf(
              PRODUCT_PRINT_FILES_LIMITATIONS.map((e) => e.toString()),
              (obj) => {
                const value = obj.value as string;
                if (value === "0") {
                  return `Please upload ${PRODUCT_PRINT_FILES_LIMITATIONS.join(" or ")} files.`;
                }
                if (!PRODUCT_PRINT_FILES_LIMITATIONS.includes(+value)) {
                  return `Please upload ${PRODUCT_PRINT_FILES_LIMITATIONS.join(
                    " or "
                  )} files. You have uploaded ${value} files.`;
                }

                return `Please upload ${PRODUCT_PRINT_FILES_LIMITATIONS.join(" or ")} files.`;
              }
            )
          })
        );
        break;
      default:
    }
  }, [activeStep]);

  return (
    <div className="d-flex flex-column">
      <PageTitle title="Create product" subTitle="Create a new product by following the steps below." />
      <div className="row">
        <div className="col-12">
          <div className="h-100">
            <WizardStepNavigation
              stepsData={STEPS_DATA}
              activeStep={activeStep}
              onStepChange={handleActiveStepChange}
            />
            <div className="card border mb-0">
              <CardBody>
                <FormProvider {...methods}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    {stepContent}
                    <WizardStepControls
                      stepsMap={STEPS_MAP}
                      firstStep={ProductWizardStep.PRODUCT_SELECTION}
                      activeStep={activeStep}
                      onMove={handleActiveStepChange}
                    />
                  </form>
                </FormProvider>
              </CardBody>
            </div>
          </div>
        </div>
        {/* <div className="col-3"> */}
        {/*  <ProductCreationSummary /> */}
        {/* </div> */}
      </div>
    </div>
  );
};
