import React, { useMemo, useState } from "react";

import { toast } from "react-toastify";
import { Button, Card, CardBody } from "reactstrap";

import { IMockupEntity, IMockupFile } from "../../common/interfaces/mockup.inteface";
import { downloadMockupFile } from "../../common/utils/file.util";
import { getErrorMessage, getImageUrl } from "../../common/utils/formatter.util";
import { useServiceContainer } from "../../hooks/useServiceContainer";
import { Loader } from "../common/Loader";

interface Props {
  selectedNode: IMockupEntity | IMockupFile;
}

interface MockupEntityPreviewProps {
  file: IMockupFile;
  canDownload?: boolean;
}

export const MockupEntityPreview: React.FC<MockupEntityPreviewProps> = ({ file, canDownload }) => {
  return (
    <div className="mockup-preview h-100">
      <div className="card border h-100">
        {canDownload && (
          <div className="mockup-preview__download">
            <Button
              color="info"
              type="button"
              onClick={() => {
                downloadMockupFile(file);
              }}
            >
              <i className="bx bx-download" />
            </Button>
          </div>
        )}
        <div className="mockup-preview__image">
          <img
            className="card-img-top img-fluid rounded-top"
            src={file.url ? getImageUrl(file.url) : file.image}
            alt="mockup preview"
          />
        </div>
        <div className="mockup-preview__text">{file.url ? file.url.split("/").pop() : (file.name as string)}</div>
      </div>
    </div>
  );
};

export const MockupListPreview: React.FC<Props> = ({ selectedNode }) => {
  const { commonService } = useServiceContainer();
  const [loader, setLoader] = useState(false);
  const isFile = useMemo(() => {
    return selectedNode && "url" in selectedNode;
  }, [selectedNode]);

  const isGroup = useMemo(() => {
    return selectedNode && "files" in selectedNode;
  }, [selectedNode]);

  const handleDownloadClick = async () => {
    setLoader(true);
    try {
      if (isGroup) {
        await commonService.downloadZipArchiveFromStorageUrls(
          (selectedNode as IMockupEntity).files.map((file) => [file.url!]),
          (selectedNode as IMockupEntity).id
        );
      } else if (isFile) {
        const file = selectedNode as IMockupFile;
        downloadMockupFile(file);
      }
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  return (
    <Card className="mockup-list-preview h-100 mb-0">
      {loader && <Loader />}
      <CardBody className="d-flex flex-column">
        <h3>Preview</h3>

        {isFile && (
          <div className="row flex-grow-1">
            <div className="col-12">
              <MockupEntityPreview file={selectedNode as IMockupFile} />
            </div>
          </div>
        )}

        {isGroup && (
          <div className="row">
            {(selectedNode as IMockupEntity).files.map((file, idx) => (
              <div className="col-3 mb-3" key={idx}>
                <MockupEntityPreview file={file} canDownload />
              </div>
            ))}
          </div>
        )}

        <Button color="info" className="mt-auto" type="button" onClick={handleDownloadClick}>
          <i className="bx bx-download me-2" />
          Download
        </Button>
      </CardBody>
    </Card>
  );
};
