import React, { useCallback, useEffect, useMemo, useState } from "react";

import { FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

import {
  AUTO_MOCKUP_WIZARD_STEPS_DATA,
  AutoMockupWizardStep
} from "../../../../common/interfaces/mockup-wizard.interface";
import { IMockupEntity, IMockupFile } from "../../../../common/interfaces/mockup.inteface";
import { dataURLtoFile } from "../../../../common/utils/file.util";
import { getErrorMessage } from "../../../../common/utils/formatter.util";
import { useCustomForm } from "../../../../hooks/useCustomForm";
import { useServiceContainer } from "../../../../hooks/useServiceContainer";
import { useAppSelector } from "../../../../store/store";
import { Loader } from "../../../common/Loader";
import { WizardStepControls } from "../../../wizards/common/WizardStepControls";
import { WizardStepNavigation } from "../../../wizards/common/WizardStepNavigation";
import { ProductTypeSelect } from "../common/ProductTypeSelect";
import { MockupDesignSelect } from "./DesignSelect";
import { GeneratedMockups } from "./GeneratedMockups";
import { AutoMockupGeneratorSettings } from "./Settings";

// const DESIGN_COUNT_LIMITATIONS = [1, 3, 5];

interface Props {
  onSubmit: () => void;
  onReset: () => void;
}

const STEPS_MAP = {
  [AutoMockupWizardStep.PRODUCT_TYPE_SELECT]: {
    next: AutoMockupWizardStep.DESIGN_SELECT,
    previous: null
  },
  [AutoMockupWizardStep.DESIGN_SELECT]: {
    next: AutoMockupWizardStep.SETTINGS,
    previous: AutoMockupWizardStep.PRODUCT_TYPE_SELECT
  },
  [AutoMockupWizardStep.SETTINGS]: {
    next: null,
    previous: AutoMockupWizardStep.DESIGN_SELECT
  }
};

export const AutoMockupWizard: React.FC<Props> = ({ onSubmit, onReset }) => {
  const [step, setStep] = useState<AutoMockupWizardStep>(AutoMockupWizardStep.PRODUCT_TYPE_SELECT);
  const [loader, setLoader] = useState(false);
  const { mockupService } = useServiceContainer();

  const [generatedMockups, setGeneratedMockups] = useState<IMockupEntity | null>(null);

  const stateValues = useAppSelector((state) => ({
    selectedProductType: state.productTypes.selectedProduct,
    selectedDesigns: state.productDesigns.selectedDesigns
  }));

  const navigate = useNavigate();

  const methods = useCustomForm({
    schema: Yup.object().shape({})
  });

  const { handleSubmit, updateSchema, getValues, watch } = methods;

  const previewFiles = watch("previewFiles");

  const formCanBeSubmitted = useMemo(() => {
    return !!previewFiles?.length;
  }, [previewFiles]);

  const handleReset = () => {
    setGeneratedMockups(null);
    methods.reset();
    onReset();
    setStep(AutoMockupWizardStep.PRODUCT_TYPE_SELECT);
  };

  const stepContent = useMemo(() => {
    switch (step) {
      case AutoMockupWizardStep.PRODUCT_TYPE_SELECT:
        return <ProductTypeSelect />;
      case AutoMockupWizardStep.DESIGN_SELECT:
        return <MockupDesignSelect />;
      case AutoMockupWizardStep.SETTINGS:
        return <AutoMockupGeneratorSettings />;
      case AutoMockupWizardStep.GENERATED_MOCKUPS:
        return (
          <GeneratedMockups
            onGenerateNewMockup={() => {
              handleReset();
            }}
            data={generatedMockups as IMockupEntity}
          />
        );
      default:
        return <>not implemented</>;
    }
  }, [step, onSubmit, generatedMockups]);

  const handleStepChange = useCallback((newStep: AutoMockupWizardStep) => {
    setStep(newStep);
  }, []);

  const onSubmitHandler = () => {
    const current = (STEPS_MAP as any)[step];
    if (!current) return;
    if (current.next) {
      setStep(current.next);
    } else {
      const formValues = getValues();
      saveMockups(formValues).then(() => null);
    }
  };

  const saveMockups = async (values: any) => {
    setLoader(true);
    try {
      const res = await mockupService.uploadMockups({
        product_id: values.productType,
        sku_number: values.skuNumber,
        files: values.previewFiles.map((e: IMockupFile) => dataURLtoFile([e.image!, e.name!]))
      });

      toast.success("Mockups saved successfully");

      navigate("/mockups/list");

      // setGeneratedMockups(res);

      // setStep(AutoMockupWizardStep.GENERATED_MOCKUPS);
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    switch (step) {
      case AutoMockupWizardStep.PRODUCT_TYPE_SELECT:
        updateSchema(
          Yup.object().shape({
            productType: Yup.string().required("Please select product")
          })
        );
        break;
      case AutoMockupWizardStep.DESIGN_SELECT:
        updateSchema(
          Yup.object().shape({
            selectedDesigns: Yup.array()
              .min(1, "Please select at least one design")
              .max(5, "Please select no more than 5 designs")
              .required("Please select at least one design")
          })
        );
        break;
      case AutoMockupWizardStep.SETTINGS:
        updateSchema(
          Yup.object().shape({
            skuNumber: Yup.string().required("This field is required"),
            assetModel: Yup.string().required("This field is required"),
            assetColor: Yup.string().required("This field is required"),
            mockupBackgroundColor: Yup.string().required("This field is required"),
            optionsShowNumbers: Yup.boolean(),
            previewFiles: Yup.array().of(Yup.mixed())
          })
        );
        break;
      default:
    }
  }, [step]);

  const wizardPassedSteps = useMemo(() => {
    const steps = [];

    if (!stateValues) return [];

    if (stateValues.selectedProductType && step !== AutoMockupWizardStep.PRODUCT_TYPE_SELECT) {
      steps.push(AutoMockupWizardStep.PRODUCT_TYPE_SELECT);
    }

    if (stateValues.selectedDesigns.length && step !== AutoMockupWizardStep.DESIGN_SELECT) {
      steps.push(AutoMockupWizardStep.DESIGN_SELECT);
    }

    return steps;
  }, [stateValues]);

  return (
    <div className="row">
      {loader && <Loader />}
      <div className="col-8 mx-auto">
        <div className="row">
          {step !== AutoMockupWizardStep.GENERATED_MOCKUPS && (
            <div className="col-auto mx-auto">
              <WizardStepNavigation
                passedSteps={wizardPassedSteps}
                stepsData={AUTO_MOCKUP_WIZARD_STEPS_DATA}
                activeStep={step}
                onStepChange={(e) => setStep(e)}
              />
            </div>
          )}
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmitHandler)}>
              <div className="col-12 mt-3">{stepContent}</div>
              {step !== AutoMockupWizardStep.GENERATED_MOCKUPS && (
                <WizardStepControls
                  smallTopOffset
                  lastStepIsSubmit
                  canBeSubmitted={formCanBeSubmitted}
                  firstStep={AutoMockupWizardStep.PRODUCT_TYPE_SELECT}
                  stepsMap={STEPS_MAP}
                  activeStep={step}
                  onMove={handleStepChange}
                />
              )}
            </form>
          </FormProvider>
        </div>
      </div>
    </div>
  );
};
