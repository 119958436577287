export enum ProductType {
  PHONE_CASE = "PHONE_CASE",
  SCREEN_PROTECTOR = "SCREEN_PROTECTOR",
  TSHIRT = "T-SHIRT",
  MACBOOK_CASE = "MACBOOK_CASE",
  IPAD_CASE = "IPAD_CASE",
  AIRPODS_CASE = "AIRPODS_CASE"
}

export interface IProductItem {
  id: number;
  title: string;
  image: string;
  image_hover: string;
  category: string;
  type: ProductType;
  description: string;
  gallery_images: string[];
  ignore_balance: boolean;
  productCount: number;
  categoryId: number;
  default_price: string;
  status: number;
  printfile_width: number;
  printfile_height: number;
  max_designs: number;
  price: {
    min: any;
    max: any;
  };
}

export interface ICategoryItem {
  id: number;
  code: string;
  title: string;
  image: string;
  deleted_at: string | null;
  created_at: string | null;
  updated_at: string | null;
}

export interface IProductMaterial {
  id: number;
  title: string;
  price: number | null;
  image: string | null;
  created_at: string | null;
  updated_at: string | null;
  deleted_at: string | null;
}

export enum ProductAssetType {
  PHONE_CASE = "PHONE_CASE"
}

export interface IProductAssetItem {
  id: number;
  title: string;
  image: string;
  type: ProductType;
  description: string;
  price: number;
  balance: number;
  reserved: number;
  is_deleted: boolean;
}

export interface BooleanCheckboxValue {
  value: string;
  label: string;
}

export interface IDesignItem {
  id: number;
  user_id: number;
  title: string;
  comment: string;
  productGroup: {
    id: number;
    title: string;
  };
  productId: number | null;
  type: ProductType;
  image: string;
  isDeleted: number;
}

export interface ICustomDesignItem {
  id: number;
  title: string;
  description?: string;
  image: [string, string];
}

export interface IProductList {
  all: IProductItem[];
  top: IProductItem[];
  categories: ICategoryItem[];
}

export interface IProductDetails {
  description: string;
  gallery_images: string[];
}

export interface IProductDeliveryService {
  id: number;
  material_id: number;
  delivery_service_id: number;
  created_at: string;
  updated_at: string;
  delivery_service: {
    id: number;
    title: string;
  };
}
