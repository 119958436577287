import React, { useState } from "react";

import classNames from "classnames";
import { Tooltip } from "reactstrap";

interface Props {
  id: string;
  content: any;
  error?: boolean;
  children?: any;
  placement?: any;
  hoverable?: boolean;
  autohide?: boolean;
}

export const TooltipIcon: React.FC<Props> = ({ id, content, error, children, placement, hoverable, autohide }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <span
      className={classNames("tooltip-icon-wrapper", {
        "tooltip-icon-wrapper--hoverable": hoverable
      })}
    >
      <Tooltip
        placement={placement || "top"}
        modifiers={{
          preventOverflow: {
            enabled: false
          }
        }}
        isOpen={isOpen}
        target={id}
        autohide={autohide ?? false}
        toggle={() => {
          setIsOpen(!isOpen);
        }}
      >
        {content}
      </Tooltip>
      {children || <span className={error ? "bx bx-error-circle" : "tooltip-icon"} id={id} />}
    </span>
  );
};
