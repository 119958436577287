import React from "react";

import { CardBody } from "reactstrap";

interface Props {
  title: string;
  subTitle?: string;
}

export const PageTitle: React.FC<Props> = ({ title, subTitle }) => {
  return (
    <div className="page-title">
      <div className="card border">
        <CardBody>
          <h1 className="page-title__title">{title}</h1>
          {subTitle && <h6 className="page-title__sub-title">{subTitle}</h6>}
        </CardBody>
      </div>
    </div>
  );
};
