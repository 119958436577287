/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { useRef } from "react";

// //Import Scrollbar
// MetisMenu
import classNames from "classnames";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

// i18n
import SimpleBar from "simplebar-react";

import { ExitArrowIcon } from "../../assets/images/icons/svg/ExitArrowIcon";
import { NotificationType } from "../../common/interfaces/notification.interface";
import { UserRole } from "../../common/interfaces/user.interface";
import { useRole } from "../../hooks/useRole";
import { APP_SET_SIDEBAR_TYPE, selectSidebarType } from "../../store/app/appSlice";
import { useAppSelector } from "../../store/store";

const MENU_ITEMS: {
  public: {
    path: string;
    title: string | ((...args: any) => JSX.Element);
    icon: string;
    id?: string;
  }[];
  admin: {
    path: string;
    title: string | ((...args: any) => JSX.Element);
    icon: string;
  }[];
} = {
  public: [
    {
      path: "/",
      title: "Dashboard",
      icon: "bxs-dashboard",
      id: "nav-dashboard"
    },
    // {
    //   path: "/categories",
    //   title: "Products",
    //   icon: "bx-list-ul"
    // },
    // {
    //   path: "/listings",
    //   title: "My Listings",
    //   icon: "bx-layer"
    // },
    // {
    //   path: "/products/create",
    //   title: "Create Product",
    //   icon: "bxs-add-to-queue"
    // },
    {
      path: "/orders/create",
      title: "Create Order",
      icon: "bxs-add-to-queue",
      id: "nav-create-order"
    },
    {
      path: "/orders/list",
      title: "Order History",
      icon: "bx-history",
      id: "nav-history"
    },
    {
      path: "/invoices",
      // title: "Invoices",
      icon: "bxs-file",
      id: "nav-invoices",
      title: (notificationCount: any) => {
        return (
          <>
            Invoices {notificationCount > 0 ? <span className="badge bg-success mt-0">{notificationCount}</span> : ""}
          </>
        );
      }
    },
    {
      path: "/downloads",
      title: "Downloads",
      icon: "bx-cloud-download",
      id: "nav-downloads"
    },
    {
      path: "/courses",
      title: "Courses",
      icon: "bx-book-content",
      id: "nav-courses"
    },
    {
      path: "/mockup",
      title: "Create Mockup",
      icon: "bx-landscape",
      id: "nav-create-mockup"
    },
    {
      path: "/mockups/list",
      title: "Mockup library",
      icon: "bx-landscape",
      id: "nav-mockup-lib"
    },
    {
      path: "/print-files",
      title: "Print files",
      icon: "bxs-file-image",
      id: "nav-print-files"
    },
    {
      path: "/cart",
      title: (orderCount: any) => {
        return (
          <>
            Order cart <span className="badge bg-success mt-0">{orderCount}</span>
          </>
        );
      },
      id: "nav-cart",
      icon: "bxs-cart-alt"
    }
    // {
    //   path: "#",
    //   title: "Learning",
    //   icon: "bxs-book-content"
    // },
    // {
    //   path: "#",
    //   title: "Downloads",
    //   icon: "bxs-download"
    // }
  ],
  admin: [
    {
      path: "/backoffice/categories",
      title: "Category management",
      icon: "bx-list-ul"
    },
    {
      path: "/backoffice/products",
      title: "Product management",
      icon: "bx-list-ul"
    },
    {
      path: "/backoffice/users",
      title: "Users management",
      icon: "bx-list-ul"
    },
    {
      path: "/backoffice/customers",
      title: "Customers management",
      icon: "bx-list-ul"
    },
    {
      path: "/backoffice/orders",
      title: "Order management",
      icon: "bx-list-ul"
    },
    {
      path: "/backoffice/warehouse",
      title: "Warehouse management",
      icon: "bx-list-ul"
    },
    {
      path: "/backoffice/invoices",
      title: "Invoice management",
      icon: "bx-list-ul"
    },
    {
      path: "/backoffice/delivery",
      title: "Delivery management",
      icon: "bx-list-ul"
    },
    {
      path: "/backoffice/messages",
      title: "Messages management",
      icon: "bx-list-ul"
    },
    {
      path: "/backoffice/downloads",
      title: "Download management",
      icon: "bx-list-ul"
    },
    {
      path: "/backoffice/courses",
      title: "Courses management",
      icon: "bx-list-ul"
    }
  ]
};

const SidebarContent = (props: any) => {
  const ref = useRef<any>();
  const location = useLocation();
  const currentPath = location.pathname;

  const sideBarType = useSelector(selectSidebarType);
  const orderCountInCart = useAppSelector((state) => state.app.orderCart.data.length);
  const invoicesNotificationCount = useAppSelector(
    (state) => state.app.notifications.data.filter((e) => e.notification_type === NotificationType.Invoices).length
  );
  const dispatch = useDispatch();
  const { hasRole } = useRole();

  function tToggle() {
    /*
      set logic for changing sidebar
    */
    if (document.body.clientWidth >= 993) {
      // desktop view
      if (sideBarType === "default") {
        dispatch(APP_SET_SIDEBAR_TYPE("small"));
      } else if (sideBarType === "small" || sideBarType === "compact") {
        dispatch(APP_SET_SIDEBAR_TYPE("default"));
      }
    } else {
      // mobile view
      document.body.classList.toggle("sidebar-enable");
      dispatch(APP_SET_SIDEBAR_TYPE("default"));
    }
  }

  return (
    <>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li
              className="menu-title cursor-pointer"
              role="button"
              onClick={() => {
                tToggle();
              }}
            >
              <div className="svg-icon">
                <ExitArrowIcon />
              </div>
              <span>{props.t("Menu")}</span>
            </li>
            {MENU_ITEMS.public
              .filter((e) => {
                const isCartItem = e.path === "/cart";
                if (isCartItem && orderCountInCart === 0) return false;

                return true;
              })
              .map((item, index) => {
                const isCartItem = item.path === "/cart";
                const isInvoiceItem = item.path === "/invoices";
                const titleArgs = isCartItem ? [orderCountInCart] : isInvoiceItem ? [invoicesNotificationCount] : [];
                return (
                  <li
                    key={index}
                    className={classNames({
                      "mm-active": currentPath === item.path
                    })}
                  >
                    <Link
                      to={item.path}
                      id={item.id ?? ""}
                      className={classNames({
                        active: currentPath === item.path
                      })}
                    >
                      <i className={`bx ${item.icon}`} />
                      <span>{typeof item.title === "function" ? item.title(...titleArgs) : item.title}</span>
                    </Link>
                  </li>
                );
              })}

            {hasRole(UserRole.Admin) ? (
              <>
                <li className="menu-title mt-3">
                  <span>Backoffice</span>
                </li>
                {MENU_ITEMS.admin.map((item, index) => (
                  <li
                    key={index}
                    className={classNames({
                      "mm-active": currentPath === item.path
                    })}
                  >
                    <Link
                      to={item.path}
                      className={classNames({
                        active: currentPath === item.path
                      })}
                    >
                      <i className={`bx ${item.icon}`} />
                      <span>{typeof item.title === "function" ? item.title() : item.title}</span>
                    </Link>
                  </li>
                ))}
              </>
            ) : (
              ""
            )}
          </ul>
        </div>
      </SimpleBar>
    </>
  );
};

export default withTranslation()(SidebarContent);
