export default [
  {
    id: "1",
    title: "Material #1",
    price_usd: 5,
    image: "https://picsum.photos/200/200"
  },
  {
    id: "2",
    title: "Material #2",
    price_usd: 5,
    image: "https://picsum.photos/200/200"
  },
  {
    id: "3",
    title: "Material #3",
    price_usd: 5,
    image: "https://picsum.photos/200/200"
  },
  {
    id: "4",
    title: "Material #4",
    price_usd: 5,
    image: "https://picsum.photos/200/200"
  },
  {
    id: "5",
    title: "Material #6",
    price_usd: 5,
    image: "https://picsum.photos/200/200"
  },
  {
    id: "6",
    title: "Material #7",
    price_usd: 5,
    image: "https://picsum.photos/200/200"
  },
  {
    id: "7",
    title: "Material #8",
    price_usd: 5,
    image: "https://picsum.photos/200/200"
  }
] as any[];
