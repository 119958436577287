export interface IMockupAvailableSettings {
  asset_types: string[];
  asset_colors: string[];
  background_color: string[];
  options: MockupOption[];
}

export interface IMockupSettings {
  asset_type: string;
  asset_color: string;
  mockup_background_color: string;
  options: {
    [key in MockupOption]: boolean;
  };
}

export interface IGenerateMockupRequest {
  sku_number: string;
  product_id: number;
  design_ids: number[];
  mockup_settings: IMockupSettings;
}

export interface IUploadMockupsRequest {
  sku_number: string;
  product_id: number;
  files: File[];
}

export interface IMockupEntity {
  id: string;
  files: IMockupFile[];
}

export interface IMockupFile {
  id: string;
  url?: string;
  name?: string;
  image?: string; // url or base64
}

export enum MockupOption {
  ShowNumbers = "show_numbers"
}
