import React, { useEffect, useState } from "react";

import { useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Alert, Button, CardBody } from "reactstrap";

import { PRODUCT_PRINT_FILES_LIMITATIONS } from "../../../../common/constants/product.constant";
import { blobToFile, dataURLtoFile, toBase64 } from "../../../../common/utils/file.util";
import { useAppSelector } from "../../../../store/store";
import {
  PRODUCT_SET_PRODUCT_MOCKUP_FILES,
  PRODUCT_SET_PRODUCT_PRINT_FILES
} from "../../../../store/wizards/productSlice";
import { FileUploadStep } from "../../../modals/create-design/FileUploadStep";
// import { GenerateMockupModal } from "../../../modals/generate-mockup/GenerateMockupModal";

interface Props {}

export const ProductDesignStep: React.FC<Props> = () => {
  const printFiles = useAppSelector((state) => state.productWizard.productPrintFiles);
  const mockupFiles = useAppSelector((state) => state.productWizard.productMockupFiles);
  const [showGenerateMockupModal, setShowGenerateMockupModal] = useState(false);
  const dispatch = useDispatch();

  const {
    register,
    setValue,
    trigger,
    formState: { errors }
  } = useFormContext();

  useEffect(() => {
    register("printFiles");
  }, [register]);

  useEffect(() => {
    setValue("printFiles", printFiles.length);
    if (printFiles.length) {
      trigger("printFiles");
    }
  }, [printFiles]);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card border">
            <CardBody>
              <div className="row">
                <div className="col-5 mx-auto">
                  {errors.printFiles?.message ? (
                    <Alert color="danger">{errors.printFiles.message as string}</Alert>
                  ) : (
                    ""
                  )}
                  <FileUploadStep
                    hideSubmitButton
                    alwaysShowTitle
                    title="Print files:"
                    onUpload={async (files) => {
                      const base64Files: [string, string][] = [];

                      for (const file of files) {
                        // eslint-disable-next-line no-await-in-loop
                        const base64File = await toBase64(file);
                        base64Files.push([base64File, file.name]);
                      }

                      dispatch(PRODUCT_SET_PRODUCT_PRINT_FILES(base64Files));
                    }}
                    uploadedFiles={printFiles.map((e) => dataURLtoFile(e))}
                    containerSize={12}
                    description={
                      <>
                        Upload your print files, and we will take care of the rest. <br />
                        The number of print files can be <b>1, 3 or 5</b>.
                      </>
                    }
                    limitations={PRODUCT_PRINT_FILES_LIMITATIONS}
                  />
                </div>
              </div>
            </CardBody>
          </div>
          {printFiles.length ? (
            <div className="card border">
              <CardBody>
                {mockupFiles.length ? (
                  <>
                    <FileUploadStep
                      alwaysShowTitle
                      readonly
                      title="Generated mockups:"
                      hideSubmitButton
                      hideAlert
                      containerSize={5}
                      description={
                        <>
                          Upload your print files, and we will take care of the rest. <br />
                          The number of print files can be <b>1, 3 or 5</b>.
                        </>
                      }
                      uploadedFiles={mockupFiles.map((e) => dataURLtoFile(e))}
                    />
                  </>
                ) : (
                  ""
                )}
                <div className="row">
                  <div className="col-12 d-flex justify-content-center">
                    <Button
                      color={mockupFiles.length ? "warning" : "primary"}
                      onClick={() => {
                        setShowGenerateMockupModal(true);
                      }}
                    >
                      {mockupFiles.length ? "Regenerate mockups" : "Generate mockups"}
                    </Button>
                  </div>
                </div>
              </CardBody>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      {/* <GenerateMockupModal */}
      {/*  show={showGenerateMockupModal} */}
      {/*  printFiles={printFiles.map((e) => dataURLtoFile(e))} */}
      {/*  onSubmit={async (files) => { */}
      {/*    const base64Files: [string, string][] = []; */}

      {/*    for (const file of files) { */}
      {/*      // eslint-disable-next-line no-await-in-loop */}
      {/*      const base64File = await toBase64(file); */}
      {/*      base64Files.push([base64File, file.name]); */}
      {/*    } */}

      {/*    dispatch(PRODUCT_SET_PRODUCT_MOCKUP_FILES(base64Files)); */}
      {/*  }} */}
      {/*  toggle={() => { */}
      {/*    setShowGenerateMockupModal(!showGenerateMockupModal); */}
      {/*  }} */}
      {/* /> */}
    </>
  );
};
