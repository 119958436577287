import React, { useEffect, useMemo, useState } from "react";

import TreeView, { flattenTree } from "react-accessible-treeview";
import { ITreeViewOnSelectProps } from "react-accessible-treeview/dist/TreeView";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Card, CardBody } from "reactstrap";

import { IMockupEntity, IMockupFile } from "../../common/interfaces/mockup.inteface";
import {
  addPostfixToFileName,
  formatProductType,
  getErrorMessage,
  getImageUrl
} from "../../common/utils/formatter.util";
import { TextFieldInput } from "../../components/common/Input/TextFieldInput";
import { Loader } from "../../components/common/Loader";
import { SelectField } from "../../components/common/SelectField";
import { TooltipIcon } from "../../components/common/TooltipIcon";
import { MockupListPreview } from "../../components/MockupList/MockupListPreview";
import { useServiceContainer } from "../../hooks/useServiceContainer";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { PRODUCT_TYPES_SET_SELECTED } from "../../store/wizards/productTypesSlice";
import { PRODUCT_TYPES_ACTION_UPDATE_LIST } from "../../store/wizards/ThunkActions/productTypesSliceThunkActions";

export const MockupListPage = () => {
  const treeViewRef = React.useRef<any>(null);
  const { mockupService } = useServiceContainer();
  const [loader, setLoader] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [mockupList, setMockupList] = useState<IMockupEntity[]>([]);
  const dispatch = useAppDispatch();
  const [selectedNode, setSelectedNode] = useState<IMockupEntity | IMockupFile | null>(null);
  const {
    productList: { data: productTypes, loader: productTypesLoader },
    selectedProduct
  } = useAppSelector((state) => state.productTypes);

  const navigate = useNavigate();

  const mockupListForTreeView = useMemo(() => {
    return flattenTree({
      name: "",
      children: mockupList.map((item) => ({
        id: item.id,
        name: item.id,
        children: item.files.map((mockup) => ({
          id: mockup.id,
          name: mockup.url ? (mockup.url.split("/").pop() as string) : (mockup.name as string)
        }))
      }))
    });
  }, [mockupList]);

  const productTypesOptions = useMemo(() => {
    return productTypes.all.map((item) => ({
      label: `[${formatProductType(item.type)}] ${item.title}`,
      value: item.id.toString()
    }));
  }, [productTypes]);

  const handleProductTypeChange = (data: any) => {
    const product = productTypes.all.find((e) => e.id.toString() === data.value);
    if (product) {
      dispatch(PRODUCT_TYPES_SET_SELECTED(product));
    }
  };

  const listErrorMessage = useMemo(() => {
    if (!selectedProduct) {
      return "Please select a product type to see the mockup list.";
    }

    if (!mockupList.length && !loader) {
      return "No mockups found for the selected product type.";
    }

    return "";
  }, [selectedProduct, mockupList, loader]);

  const fetchMockupList = async () => {
    if (!selectedProduct) return;

    setLoader(true);

    try {
      const list = await mockupService.getMockupList(selectedProduct.id);

      setMockupList(list);
    } catch (e) {
      console.error(e);
    } finally {
      setLoader(false);
    }
  };

  const handleTreeViewSelect = (data: ITreeViewOnSelectProps) => {
    if (data.isSelected) {
      // it's a file
      if (data.element.parent) {
        const mockup = mockupList.find((e) => e.id === data.element.parent);
        if (mockup) {
          const file = mockup.files.find((e) => e.id === data.element.id);
          if (file) {
            setSelectedNode(file);
          }
        }
      } else {
        const mockup = mockupList.find((e) => e.id === data.element.id);
        if (mockup) {
          setSelectedNode(mockup);
        }
      }
    }
  };

  const handleCreateNewMockupClick = () => {
    navigate("/mockup");
  };

  const handleFolderDelete = async (id: string) => {
    setLoader(true);
    try {
      await mockupService.deleteMockupFolderById(id);

      setMockupList(mockupList.filter((e) => e.id !== id));

      toast.success("Mockup folder deleted successfully.");
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    dispatch(PRODUCT_TYPES_ACTION_UPDATE_LIST());
  }, []);

  useEffect(() => {
    if (selectedProduct) {
      fetchMockupList().then(() => null);
    }
  }, [selectedProduct]);

  return (
    <div className="position-relative d-flex flex-column flex-grow-1">
      {loader || productTypesLoader ? <Loader /> : null}
      <div className="row">
        <div className="col-3">
          <SelectField
            options={productTypesOptions}
            placeholder="Select product type"
            required
            value={productTypesOptions.find((e) => e.value === selectedProduct?.id.toString()) || null}
            onChange={handleProductTypeChange}
          />
        </div>
        <div className="col-2">
          <Button color="primary" onClick={handleCreateNewMockupClick} className="w-100">
            Create new mockup
          </Button>
        </div>
      </div>
      <div className="row flex-grow-1">
        <div className="col-5">
          <Card className="h-100">
            <CardBody>
              <h3>Mockup List</h3>

              {listErrorMessage ? (
                <div className="d-flex h-100 align-items-center justify-content-center">
                  <p>{listErrorMessage}</p>
                </div>
              ) : (
                <div className="tree-view">
                  <div className="mb-3">
                    <TextFieldInput
                      value={searchInputValue}
                      onChange={(e) => {
                        setSearchInputValue(e.target.value);
                      }}
                      placeholder="Search.."
                    />
                  </div>
                  <TreeView
                    ref={treeViewRef}
                    onSelect={handleTreeViewSelect}
                    data={mockupListForTreeView}
                    aria-label="directory tree"
                    nodeRenderer={({ element, isBranch, getNodeProps, level }) => {
                      if (isBranch && element) {
                        const matchFilter = element.name.toLowerCase().includes(searchInputValue.toLowerCase());
                        if (!matchFilter) {
                          return null;
                        }
                      }
                      const previewImage = isBranch
                        ? mockupList?.find((e) => e.id === element.id)?.files.find((e) => e.id === element.children[0])
                            ?.url
                        : element.name;
                      return (
                        <div {...getNodeProps()} style={{ paddingLeft: 20 * (level - 1) }}>
                          {isBranch ? (
                            <span className="tree-view__folder-preview">
                              {previewImage ? (
                                <TooltipIcon
                                  id={`preview-mockup-${element.id}`}
                                  placement="right"
                                  content={
                                    <div className="tooltip-image">
                                      <img
                                        src={getImageUrl(addPostfixToFileName(previewImage!, "_thumbnail"))}
                                        alt="preview"
                                      />
                                    </div>
                                  }
                                >
                                  <img
                                    src={getImageUrl(addPostfixToFileName(previewImage!, "_thumbnail"))}
                                    id={`preview-mockup-${element.id}`}
                                    alt="preview"
                                  />
                                </TooltipIcon>
                              ) : (
                                <i className="bx bx-folder" />
                              )}
                            </span>
                          ) : (
                            <i className="bx bx-image" />
                          )}
                          {element.name}

                          {isBranch && (
                            <button
                              className="tree-view__delete"
                              type="button"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleFolderDelete(element.id as string).then(() => null);
                              }}
                            >
                              <i className="bx bx-trash-alt" />
                            </button>
                          )}
                        </div>
                      );
                    }}
                  />
                </div>
              )}
            </CardBody>
          </Card>
        </div>
        <div className="col-7">{selectedNode && <MockupListPreview selectedNode={selectedNode} />}</div>
      </div>
    </div>
  );
};
