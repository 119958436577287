import React, { useEffect } from "react";

import { useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";

import { IProductItem } from "../../../../common/interfaces/products.interface";
import productsMock from "../../../../mocks/products.mock";
import { useAppSelector } from "../../../../store/store";
import { PRODUCT_SET_SELECTED_PRODUCT_TYPE } from "../../../../store/wizards/productSlice";
import { Alert } from "../../../common/Alert";
import { ProductRowItems } from "../../../common/products/ProductRowItems";

export const ProductSelectionStep = () => {
  const selectedProduct = useAppSelector((state) => state.productWizard.selectedProductType);
  const dispatch = useDispatch();

  const {
    register,
    setValue,
    setError,
    formState: { errors }
  } = useFormContext();

  useEffect(() => {
    register("productType");
  }, [register]);

  return (
    <>
      <Alert variant="secondary">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab, animi dolore eum excepturi illum necessitatibus
        officiis possimus rerum vel vitae voluptate voluptatem! Consectetur consequuntur earum laboriosam, nisi officia
        possimus voluptate!
      </Alert>
      <ProductRowItems
        listTitle="Select product from list:"
        previewTitle="Selected product:"
        items={productsMock.all}
        selectable
        colSize={4}
        required
        error={errors.productType?.message}
        onSelect={(item) => {
          setValue("productType", item.id);
          setError("productType", {
            type: "manual",
            message: ""
          });
          dispatch(PRODUCT_SET_SELECTED_PRODUCT_TYPE(item as IProductItem));
        }}
        selectedValue={selectedProduct}
      />
    </>
  );
};
