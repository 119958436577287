import React from "react";

import { useSelector } from "react-redux";
import { Card, CardBody, Col, Row } from "reactstrap";

import { IProductAssetItem, IProductItem, IProductMaterial } from "../../../../common/interfaces/products.interface";
import { blobToFile, dataURLtoFile } from "../../../../common/utils/file.util";
import { formatAssetType } from "../../../../common/utils/formatter.util";
import { useAppSelector } from "../../../../store/store";
import { FileUploadStep } from "../../../modals/create-design/FileUploadStep";

export const ProductSummaryStep: React.FC = () => {
  const summaryData = useAppSelector((state) => ({
    productType: state.productWizard.selectedProductType as IProductItem,
    productAsset: state.productWizard.selectedProductAsset as IProductAssetItem,
    productMaterial: state.productWizard.selectedProductMaterial as IProductMaterial,
    productTitle: state.productWizard.productTitle,
    productDescription: state.productWizard.productDescription,
    productSkuNumber: state.productWizard.productSkuNumber,
    productPrintFiles: state.productWizard.productPrintFiles,
    productMockupFiles: state.productWizard.productMockupFiles
  }));

  return (
    <Row>
      <Col lg={6}>
        <Card className="border">
          <CardBody>
            <div className="py-2">
              <h3 className="font-size-15 font-weight-bold">Summary</h3>
            </div>
            <div className="table-responsive">
              {summaryData.productType && (
                <div>
                  <p className="mb-1 text-muted">Product type:</p>
                  <h5 className="font-size-14">{summaryData.productType.type}</h5>
                </div>
              )}
              {summaryData.productAsset && (
                <div className="mt-3">
                  <p className="mb-1 text-muted">Product asset:</p>
                  <h5 className="font-size-14">
                    [{formatAssetType(summaryData.productAsset.type)}] {summaryData.productAsset.title}
                  </h5>
                </div>
              )}
              {summaryData.productMaterial && (
                <div className="mt-3">
                  <p className="mb-1 text-muted">Asset material:</p>
                  <h5 className="font-size-14">{summaryData.productMaterial.title}</h5>
                </div>
              )}
              <div className="mt-3">
                <p className="mb-1 text-muted">Product title:</p>
                <h5 className="font-size-14">{summaryData.productTitle}</h5>
              </div>
              <div className="mt-3">
                <p className="mb-1 text-muted">Product description:</p>
                <h5 className="font-size-14">{summaryData.productDescription}</h5>
              </div>
              <div className="mt-3">
                <p className="mb-1 text-muted">SKU Number:</p>
                <h5 className="font-size-14">{summaryData.productSkuNumber}</h5>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col lg={6}>
        <Card className="border">
          <CardBody>
            <FileUploadStep
              alwaysShowTitle
              readonly
              title="Print files:"
              hideSubmitButton
              hideAlert
              containerSize={12}
              uploadedFiles={summaryData.productPrintFiles.map((e) => dataURLtoFile(e))}
            />
            <FileUploadStep
              alwaysShowTitle
              readonly
              title="Mockup files:"
              hideSubmitButton
              hideAlert
              containerSize={12}
              uploadedFiles={summaryData.productMockupFiles.map((e) => dataURLtoFile(e))}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
