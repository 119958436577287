import React from "react";

import classNames from "classnames";

import { Variant } from "../../common/interfaces/boostrap-base";

interface Props {
  variant: Variant;
  children: any;
  [key: string]: string;
}

export const Alert: React.FC<Props> = ({ variant, children, ...rest }) => {
  return (
    <div className={classNames("alert", `alert-${variant}`)} {...rest} role="alert">
      {children}
    </div>
  );
};
