import React, { useEffect } from "react";

import { useFormContext } from "react-hook-form";
import { Card, CardBody } from "reactstrap";

import { IProductItem } from "../../../../common/interfaces/products.interface";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { DESIGNS_RESET_SELECTED } from "../../../../store/wizards/productDesignsSlice";
import {
  PRODUCT_TYPES_ACTION_UPDATE_LIST,
  PRODUCT_TYPES_SET_SELECTED
} from "../../../../store/wizards/productTypesSlice";
import { ProductTypesList } from "../../../common/ProductTypes/ProductTypesList";

export const ProductTypeSelect = () => {
  const dispatch = useAppDispatch();
  const selectedProduct = useAppSelector((state) => state.productTypes.selectedProduct);
  const {
    register,
    setValue,
    setError,
    formState: { errors }
  } = useFormContext();

  useEffect(() => {
    register("productType");
  }, [register]);

  useEffect(() => {
    if (!selectedProduct) return;
    setValue("productType", selectedProduct.id);
  }, [selectedProduct]);

  useEffect(() => {
    dispatch(PRODUCT_TYPES_ACTION_UPDATE_LIST());
  }, []);

  return (
    <Card>
      <CardBody>
        <ProductTypesList
          hidePrice
          selectedProduct={selectedProduct}
          error={errors.productType?.message}
          onSelect={(item) => {
            setError("productType", {
              type: "manual",
              message: ""
            });
            dispatch(PRODUCT_TYPES_SET_SELECTED(item as IProductItem));
            dispatch(DESIGNS_RESET_SELECTED());
          }}
        />
      </CardBody>
    </Card>
  );
};
