import React, { useEffect, useState } from "react";

import { useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";
import { CardBody } from "reactstrap";

import { IProductMaterial } from "../../../../common/interfaces/products.interface";
import phoneCaseMaterialsMock from "../../../../mocks/phone-case-materials.mock";
import phoneModelsMock from "../../../../mocks/phone-models.mock";
import { useAppSelector } from "../../../../store/store";
import {
  PRODUCT_SET_SELECTED_PRODUCT_ASSET,
  PRODUCT_SET_SELECTED_PRODUCT_MATERIAL
} from "../../../../store/wizards/productSlice";
import { Alert } from "../../../common/Alert";
import { ProductRowItems } from "../../../common/products/ProductRowItems";
import { SelectField } from "../../../common/SelectField";

export const ProductSettingsStep = () => {
  const selectedModel = useAppSelector((state) => state.productWizard.selectedProductAsset);
  const selectedMaterial = useAppSelector((state) => state.productWizard.selectedProductMaterial);
  // const [selectedModel, setSelectedModel] = useState<any>(null);
  // const [selectedMaterial, setSelectedMaterial] = useState<any>(null);
  const {
    register,
    setValue,
    setError,
    formState: { errors }
  } = useFormContext();

  const dispatch = useDispatch();

  const handleModelChange = (data: any) => {
    dispatch(PRODUCT_SET_SELECTED_PRODUCT_ASSET(data));
  };

  const handleMaterialChange = (data: any) => {
    dispatch(PRODUCT_SET_SELECTED_PRODUCT_MATERIAL(data));
  };

  useEffect(() => {
    register("productAssetType");
    register("productMaterial");
  }, [register]);

  return (
    <>
      <Alert variant="info">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab animi aperiam, beatae cum debitis doloremque fuga
        illum ipsa molestiae mollitia nihil suscipit! Culpa dolor, dolore doloribus earum eos possimus sunt. Asperiores
        consequuntur, dolores doloribus dolorum eligendi eos ex facere id iusto nisi numquam officiis quas quisquam quos
        unde vitae voluptas.
      </Alert>
      <div className="row">
        <div className="col-4">
          <div className="card border">
            <CardBody>
              <SelectField
                options={phoneModelsMock}
                label="Phone model"
                required
                error={errors.productAssetType?.message}
                value={selectedModel}
                onChange={(data) => {
                  handleModelChange(data);
                  setValue("productAssetType", data.value);
                  setError("productAssetType", {
                    type: "manual",
                    message: ""
                  });
                  dispatch(PRODUCT_SET_SELECTED_PRODUCT_ASSET(data));
                }}
              />
            </CardBody>
          </div>
        </div>
        <div className="col-8">
          <div className="mb-3">
            <div className="card border card-bg-highlight">
              <CardBody>
                <ProductRowItems
                  listTitle="Select material from list:"
                  previewTitle="Selected material:"
                  error={errors.productMaterial?.message}
                  required
                  items={phoneCaseMaterialsMock}
                  selectedValue={selectedMaterial}
                  onSelect={(item: any) => {
                    handleMaterialChange(item);
                    setValue("productMaterial", item.id);
                    setError("productMaterial", {
                      type: "manual",
                      message: ""
                    });
                    dispatch(PRODUCT_SET_SELECTED_PRODUCT_MATERIAL(item));
                  }}
                  selectable
                />
              </CardBody>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
