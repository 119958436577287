import React, { useCallback, useEffect, useState } from "react";

import { useFormContext } from "react-hook-form";

import { OrderInstance } from "../../../../common/interfaces/order-wizard.interface";
import { IProductAssetItem } from "../../../../common/interfaces/products.interface";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { ORDER_GET_INSTANCE, ORDER_SET_SELECTED_DESIGN } from "../../../../store/wizards/orderSlice";
import { DesignSelect } from "../../../common/DesignSelect/DesignSelect";

export const ProductDesignStep = () => {
  const dispatch = useAppDispatch();
  const {
    register,
    setValue,
    setError,
    formState: { errors }
  } = useFormContext();

  const selectedAssetImage = useAppSelector((state) => {
    const order = ORDER_GET_INSTANCE(state.orderWizard) as OrderInstance;
    return (order.selectedProductAsset as IProductAssetItem).image;
  });

  const selectedAssetType = useAppSelector((state) => {
    const order = ORDER_GET_INSTANCE(state.orderWizard) as OrderInstance;
    return (order.selectedProductAsset as IProductAssetItem).type;
  });

  const selectedDesign = useAppSelector((state) => {
    const order = ORDER_GET_INSTANCE(state.orderWizard) as OrderInstance;
    return order.selectedDesign;
  });

  const selectedProduct = useAppSelector((state) => {
    const order = ORDER_GET_INSTANCE(state.orderWizard) as OrderInstance;
    return order.selectedProductType;
  });

  useEffect(() => {
    register("selectedDesign");
  }, []);

  useEffect(() => {
    if (!selectedDesign) return;
    setValue("selectedDesign", selectedDesign.id);
  }, [selectedDesign]);

  return (
    <>
      <p className="wizard-step__description">
        Please add a design (print file) for your product. You can upload a new file or choose for your existing file
        list.
      </p>

      <DesignSelect
        selectedAssetImage={selectedAssetImage}
        selectedAssetType={selectedAssetType}
        selectedDesigns={selectedDesign}
        selectedProduct={selectedProduct}
        onDesignUpload={(item) => {
          dispatch(ORDER_SET_SELECTED_DESIGN(item));
          setError("selectedDesign", {
            type: "manual",
            message: ""
          });
        }}
        onItemSelect={(item) => {
          dispatch(ORDER_SET_SELECTED_DESIGN(item));
          setError("selectedDesign", {
            type: "manual",
            message: ""
          });
        }}
        wizardError={errors.selectedDesigns?.message}
      />
    </>
  );
};
