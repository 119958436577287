import React, { useState } from "react";

import { Steps } from "intro.js-react";
import { useSelector } from "react-redux";

import { useIntroductionSteps } from "../../hooks/useIntroductionSteps";
import { useAppSelector } from "../../store/store";

const STEPS = [
  // {
  //   title: "Create order",
  //   element: "#create-order",
  //   intro: "You can create your custom printed order here.",
  //   position: "bottom"
  // },
  {
    title: "Create order",
    element: "#nav-create-order",
    intro: "You can create your custom printed order here.",
    position: "right"
  },
  {
    title: "Order History",
    element: "#nav-history",
    intro: "All your orders will be stored here, where you can search for orders and see their statuses",
    position: "right"
  },
  {
    title: "Invoices",
    element: "#nav-invoices",
    intro: "You can find all your receipts for all paid orders here.",
    position: "right"
  },
  {
    title: "Create Mockup",
    element: "#nav-create-mockup",
    intro: "Be creative and generate mockups for all your products",
    position: "right"
  },
  {
    title: "Mockup Library",
    element: "#nav-mockup-lib",
    intro: "All your mockups will be stored here - in one safe spot so you can always find them.",
    position: "right"
  },
  {
    title: "Print Files",
    element: "#nav-print-files",
    intro: "You can upload and manage your print files here.",
    position: "right"
  },
  {
    title: "Order Cart",
    element: "#page-header-cart-dropdown",
    intro: "You can manage all your orders that have been created, but are not paid here.",
    position: "left"
  },
  {
    title: "Notifications",
    element: "#page-header-notifications-dropdown",
    intro: "You can see all the notifications regarding your orders and payments here.",
    position: "left"
  },
  {
    title: "Messages",
    element: "#page-header-messages-dropdown",
    intro: "You can find all the needed information about updates, products, mockups, etc. here.",
    position: "left"
  },
  {
    title: "Admin Panel",
    element: "#page-header-user-dropdown",
    intro: "You can edit your profile information and restart the intro tutorial here.",
    position: "left"
  }
];

export const LayoutIntroduction = () => {
  const { isEnabled, handleExit, handleComplete } = useIntroductionSteps("layout");

  return (
    <Steps
      enabled={isEnabled}
      initialStep={0}
      steps={STEPS}
      options={{
        tooltipClass: "introduction-tooltip",
        exitOnEsc: false,
        exitOnOverlayClick: false,
        showStepNumbers: true,
        showBullets: false,
        scrollToElement: false,
        scrollTo: "tooltip",
        // scrollToElement: false,
        helperElementPadding: 5
      }}
      onExit={handleExit}
      onComplete={handleComplete}
    />
  );
};
