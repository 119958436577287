import React, { useEffect, useMemo, useState } from "react";

import { useFormContext } from "react-hook-form";
import { toast } from "react-toastify";
import { Button, Card, CardBody, Label, Row } from "reactstrap";

import { IMockupAvailableSettings, IMockupFile, MockupOption } from "../../../../common/interfaces/mockup.inteface";
import { IDesignItem } from "../../../../common/interfaces/products.interface";
import { getErrorMessage, getImageUrl } from "../../../../common/utils/formatter.util";
import { useServiceContainer } from "../../../../hooks/useServiceContainer";
import { useAppSelector } from "../../../../store/store";
import { RadioFieldInput } from "../../../common/Input/RadioFieldInput";
import { TextFieldInput } from "../../../common/Input/TextFieldInput";
import { Loader } from "../../../common/Loader";
import { MockupEntityPreview } from "../../../MockupList/MockupListPreview";

interface SelectedDesignProps {
  design: IDesignItem;
}

export const SelectedDesign: React.FC<SelectedDesignProps> = ({ design }) => {
  return (
    <div className="selected-designs__item">
      <div className="selected-designs__item__inner">
        <div className="selected-designs__item__image">
          <img src={getImageUrl(design.image)} alt={design.title} />
        </div>

        <div className="selected-designs__item__title">{design.title}</div>
      </div>
    </div>
  );
};

export const AutoMockupGeneratorSettings: React.FC = ({}) => {
  const [mockupAvailableSettings, setMockupAvailableSettings] = useState<IMockupAvailableSettings | null>(null);
  const { mockupService } = useServiceContainer();

  // const [previewFiles, setPreviewFiles] = useState<IMockupFile[]>([]);

  const [loader, setLoader] = useState(true);

  const selectedDesigns = useAppSelector((state) => {
    return state.productDesigns.selectedDesigns;
  });

  const {
    register,
    watch,
    setValue,
    setError,
    trigger,
    getValues,
    formState: { errors }
  } = useFormContext();

  const optionsShowNumbers = watch("optionsShowNumbers");
  const selectedAssetModel = watch("assetModel");
  const selectedAssetColor = watch("assetColor");
  const selectedMockupBackgroundColor = watch("mockupBackgroundColor");

  const productId = watch("productType");
  const previewFiles = watch("previewFiles");

  const assetsColors = useMemo(() => {
    if (!mockupAvailableSettings) return [];

    return mockupAvailableSettings.asset_colors.map((e) => ({
      id: e,
      label: e
    }));
  }, [mockupAvailableSettings]);

  const backgroundColors = useMemo(() => {
    if (!mockupAvailableSettings) return [];

    return mockupAvailableSettings.background_color.map((e) => ({
      id: e,
      label: e
    }));
  }, [mockupAvailableSettings]);

  const assetModels = useMemo(() => {
    if (!mockupAvailableSettings) return [];

    return mockupAvailableSettings.asset_types.map((e) => ({
      id: e,
      label: e
    }));
  }, [mockupAvailableSettings]);

  const handleGenerateButtonClick = async () => {
    const isValid = await trigger();
    if (!isValid) return;

    try {
      setLoader(true);
      const formValues = getValues();

      const res = await mockupService.generateMockup({
        product_id: formValues.productType,
        design_ids: formValues.selectedDesigns,
        sku_number: formValues.skuNumber,
        mockup_settings: {
          asset_type: formValues.assetModel,
          asset_color: formValues.assetColor,
          mockup_background_color: formValues.mockupBackgroundColor,
          options: {
            show_numbers: formValues.optionsShowNumbers
          }
        }
      });

      setValue(
        "previewFiles",
        res.files.map((e) => ({
          ...e
        }))
      );
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoader(false);
    }
  };

  const previewCol = useMemo(() => {
    if (!previewFiles) return 4;

    if (previewFiles.length === 1) return 12;
    if (previewFiles.length === 2) return 6;

    return 4;
  }, [previewFiles]);

  useEffect(() => {
    if (assetModels.length && !selectedAssetModel) {
      setValue("assetModel", assetModels[0].id);
    }
  }, [assetModels]);

  useEffect(() => {
    if (backgroundColors.length && !selectedMockupBackgroundColor) {
      setValue("mockupBackgroundColor", backgroundColors[0].id);
    }
  }, [backgroundColors]);

  useEffect(() => {
    if (assetsColors.length && !selectedAssetColor) {
      setValue("assetColor", assetsColors[0].id);
    }
  }, [assetModels]);

  useEffect(() => {
    if (productId) {
      mockupService.getMockupSettings(productId).then((res) => {
        setLoader(false);
        setMockupAvailableSettings(res);
      });
    }
  }, [productId]);

  return (
    <div className="row">
      {loader && <Loader />}
      <div className="col-6">
        <Card className="h-100">
          <CardBody>
            <div className="mb-2">
              <h5 className="font-size-14 mb-2">
                Product{" "}
                {errors.assetModel?.message ? (
                  <div className="invalid-feedback d-inline">{errors.assetModel.message as string}</div>
                ) : (
                  ""
                )}
              </h5>
              <div className="d-flex mx-n1">
                {assetModels.map((item, idx) => {
                  return (
                    <RadioFieldInput
                      key={idx}
                      type="radio"
                      name="asset-model"
                      id={`model-${item.id}`}
                      onChange={() => {
                        setError("assetModel", {
                          type: "manual",
                          message: ""
                        });
                        setValue("assetModel", item.id);
                      }}
                      checked={selectedAssetModel === item.id}
                      label={item.label}
                    />
                  );
                })}
              </div>
            </div>
            <div className="mb-3">
              <h5 className="font-size-14 mb-2">
                Product color{" "}
                {errors.assetColor?.message ? (
                  <div className="invalid-feedback d-inline">{errors.assetColor.message as string}</div>
                ) : (
                  ""
                )}
              </h5>
              <div className="d-flex mx-n1">
                {assetsColors.map((item, idx) => {
                  return (
                    <RadioFieldInput
                      key={idx}
                      type="radio"
                      name="phone-color"
                      id={`phone-color-${item.id}`}
                      onChange={() => {
                        setError("assetColor", {
                          type: "manual",
                          message: ""
                        });
                        setValue("assetColor", item.id);
                      }}
                      checked={selectedAssetColor === item.id}
                      label={item.label}
                    />
                  );
                })}
              </div>
            </div>
            <div className="mb-3">
              <h5 className="font-size-14 mb-2">
                Mockup background color{" "}
                {errors.mockupBackgroundColor?.message ? (
                  <div className="invalid-feedback d-inline">{errors.mockupBackgroundColor.message as string}</div>
                ) : (
                  ""
                )}
              </h5>
              <div className="d-flex mx-n1">
                {backgroundColors.map((item, idx) => {
                  return (
                    <RadioFieldInput
                      key={idx}
                      type="radio"
                      name="mockup-background-color"
                      id={`mockup-background-color-${item.id}`}
                      onChange={() => {
                        setError("mockupBackgroundColor", {
                          type: "manual",
                          message: ""
                        });
                        setValue("mockupBackgroundColor", item.id);
                      }}
                      checked={selectedMockupBackgroundColor === item.id}
                      label={item.label}
                    />
                  );
                })}
              </div>
              {/* {selectedMockupBackgroundColor.isImage && ( */}
              {/*  <FileUploadStep */}
              {/*    title="Upload background" */}
              {/*    uploadedFiles={customMockupBackgroundImage ? [customMockupBackgroundImage] : []} */}
              {/*    onUpload={(e) => { */}
              {/*      setCustomMockupBackgroundImage(e[0]); */}
              {/*    }} */}
              {/*    hideSubmitButton */}
              {/*    small */}
              {/*    hideAlert */}
              {/*    containerSize={12} */}
              {/*  /> */}
              {/* )} */}
            </div>
            {mockupAvailableSettings?.options.filter((e) => e && typeof e === "string").length ? (
              <div className="mb-3">
                <h5 className="font-size-14 mb-2">Options</h5>
                {mockupAvailableSettings.options.includes(MockupOption.ShowNumbers) ? (
                  <div className="form-check mb-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="show-numbers"
                      onChange={(e) => {
                        setValue("optionsShowNumbers", e.target.checked);
                      }}
                      checked={optionsShowNumbers}
                    />
                    <Label className="form-check-label" for="show-numbers">
                      Show numbers
                    </Label>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}

            <div className="mb-3">
              <TextFieldInput {...register("skuNumber")} label="SKU Number" error={errors.skuNumber?.message as any} />
            </div>

            <div className="mb-3">
              <h5 className="font-size-14 mb-2">Selected designs</h5>

              <div className="selected-designs">
                {(selectedDesigns ?? []).map((design, idx) => (
                  <SelectedDesign design={design} key={idx} />
                ))}
              </div>
            </div>

            <Button color="primary" type="button" onClick={handleGenerateButtonClick}>
              Generate
            </Button>
          </CardBody>
        </Card>
      </div>
      <div className="col-6">
        <Card className="h-100">
          <CardBody>
            <h4>Preview</h4>

            <Row>
              {(previewFiles ?? []).map((file: IMockupFile, idx: number) => (
                <div className={`col-${previewCol} mb-3`} key={idx}>
                  <MockupEntityPreview file={file} canDownload />
                </div>
              ))}
            </Row>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};
