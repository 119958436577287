import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col, Button } from "reactstrap";
import SimpleBar from "simplebar-react";

import { ICartOrder, IOrderItem } from "../../../common/interfaces/orders.interface";
import { timeSince } from "../../../common/utils/formatter.util";
import { useCheckout } from "../../../hooks/useCheckout";
import { useServiceContainer } from "../../../hooks/useServiceContainer";
import { generateStripePaymentUrl } from "../../../mock.engine";
import { OrdersApiService } from "../../../services/api";
import ordersApiService from "../../../services/api/OrdersApiService";
import { APP_ACTION_GET_ORDER_CART, APP_ADD_ORDERS_TO_CART } from "../../../store/app/appSlice";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { Loader } from "../Loader";

interface Props {}

const CartDropdown = (props: Props) => {
  const [menu, setMenu] = useState(false);
  const [loader, setLoader] = useState(false);
  const dispatch = useAppDispatch();

  const { data: cartData, loader: cartLoader } = useAppSelector((state) => state.app.orderCart);

  useEffect(() => {
    dispatch(APP_ACTION_GET_ORDER_CART());
  }, []);

  return (
    <>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="dropdown d-inline-block" tag="li">
        <DropdownToggle className="btn px-3 header-item noti-icon" tag="button" id="page-header-cart-dropdown">
          <i className="mdi mdi-cart" />
          {cartData.length ? <span className="badge bg-danger rounded-pill">{cartData.length}</span> : ""}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0 dropdown-menu-end">
          {loader || (cartLoader && <Loader />)}
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0">Unpaid orders</h6>
              </Col>
              <div className="col-auto">
                {/* <Link */}
                {/*  to="/cart" */}
                {/*  className="small" */}
                {/*  onClick={() => { */}
                {/*    setMenu(false); */}
                {/*  }} */}
                {/* > */}
                {/*  {" "} */}
                {/*  View All */}
                {/* </Link> */}
              </div>
              .
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {cartData.length ? (
              cartData.map((item, index) => (
                <div className="text-reset notification-item d-block active" key={index}>
                  <div className="d-flex">
                    <div className="avatar-xs me-3">
                      <span className="avatar-title bg-primary rounded-circle font-size-16">
                        <i className="bx bx-cart" />
                      </span>
                    </div>
                    <div className="flex-1">
                      <h6 className="mt-0 mb-1">Order#{item.salesOrderId} is placed</h6>
                      <div className="font-size-12 text-muted">
                        <p className="mb-1">Please proceed to payment to complete your order. </p>
                        <div className="d-flex align-items-center justify-content-between mt-2">
                          {/* <Button */}
                          {/*  color="primary" */}
                          {/*  size="sm" */}
                          {/*  onClick={() => { */}
                          {/*    handlePayNowButton([item]).then(() => null); */}
                          {/*  }} */}
                          {/* > */}
                          {/*  Pay now */}
                          {/* </Button> */}
                          <p className="mb-0">
                            <i className="mdi mdi-clock-outline" /> {timeSince(item.created_at)}{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center p-4">
                <p>You have no unpaid orders.</p>
              </div>
            )}
          </SimpleBar>
          {cartData.length ? (
            <div className="p-3 border-top d-grid">
              <Link to="/cart" className="btn btn-sm btn-primary font-size-14 btn-block text-center">
                <i className="mdi mdi-arrow-right-circle me-1" /> View all{" "}
              </Link>
            </div>
          ) : (
            ""
          )}
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default CartDropdown;
