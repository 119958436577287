import React, { useEffect, useState } from "react";

import { useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";
import { CardBody, Label } from "reactstrap";

import {
  PRODUCT_SET_PRODUCT_DESCRIPTION,
  PRODUCT_SET_PRODUCT_SKU_NUMBER,
  PRODUCT_SET_PRODUCT_TITLE
} from "../../../../store/wizards/productSlice";
import { InputField } from "../../../common/InputField";

export const ProductMetaStep = () => {
  const dispatch = useDispatch();
  const {
    register,
    watch,
    formState: { errors }
  } = useFormContext();

  const title = watch("title");
  const description = watch("description");
  const skuNumber = watch("skuNumber");

  useEffect(() => {
    dispatch(PRODUCT_SET_PRODUCT_TITLE(title));
  }, [title]);

  useEffect(() => {
    dispatch(PRODUCT_SET_PRODUCT_DESCRIPTION(description));
  }, [description]);

  useEffect(() => {
    dispatch(PRODUCT_SET_PRODUCT_SKU_NUMBER(skuNumber));
  }, [skuNumber]);

  return (
    <>
      <div className="row">
        <div className="col-6 mx-auto">
          <div className="card border">
            <CardBody>
              <InputField
                {...register("title")}
                error={errors.title?.message}
                label="Product title"
                name="title"
                required
                placeholder="Enter product title"
              />
              <InputField
                {...register("description")}
                error={errors.description?.message}
                label="Product description"
                name="description"
                required
                placeholder="Enter product description"
              />
              <InputField
                {...register("skuNumber")}
                error={errors.skuNumber?.message}
                label="SKU number"
                name="skuNumber"
                placeholder="Enter product sku number"
              />
            </CardBody>
          </div>
        </div>
      </div>
    </>
  );
};
