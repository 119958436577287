import React from "react";

import classNames from "classnames";
import { Label } from "reactstrap";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string;
}

export const TextFieldInput = React.forwardRef<HTMLInputElement, Props>(({ label, error, ...props }, ref: any) => {
  return (
    <div className="form-group">
      {label && <Label>{label}</Label>}
      <input ref={ref} className={classNames("form-control", { "is-invalid": !!error })} {...props} />
      {!!error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
});
